import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer/Footer";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import AuditForm from "../../pages/auditForm";
import QuestionForm from "../../pages/questionForm";
import AddNewForm from "../../pages/addNewForm/addNewForm";
import ScoringTable from "../../pages/scoringTable";
// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Switch>
          <Route path="/app/dashboard" component={Dashboard} />
          <Route path="/app/typography" component={Typography} />
          <Route path="/app/tables" component={Tables} />
          <Route path="/app/notifications" component={Notifications} />
          <Route
            exact
            path="/app/ui"
            render={() => <Redirect to="/app/ui/addnewform" />}
          />
          <Route path="/app/ui/questionnaire" component={QuestionForm} />
          <Route path="/app/ui/audit" component={AuditForm} />
          <Route path="/app/ui/addnewform" component={AddNewForm} />
          <Route path="/app/ui/scoringtable" component={ScoringTable} />
        </Switch>
        {/* <Footer /> */}
      </>
    </div>
  );
}

export default withRouter(Layout);
