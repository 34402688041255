import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import { apiPost } from "../apiCommon";
import { config } from "../config";
import { useUserDispatch, signOut } from "../context/UserContext";
// context
import { useUserState } from "../context/UserContext";

// offline/online
window.addEventListener('online', () => {
   //console.log('Became online');
   localStorage.setItem('internetFlag', 'true');});
window.addEventListener('offline', () =>{
  // console.log('Became offline');
   localStorage.setItem('internetFlag', 'false');});
/*window.addEventListener("beforeunload", (ev) => 
{  
    ev.preventDefault();
    return ev.returnValue = 'Are you sure you want to close?';
});*/
export default function App(props) {
  // global
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userUID')));
  var userDispatch = useUserDispatch();
  useEffect(() => {
    const unloadCallback = async (event) => {
        event.preventDefault();
        event.returnValue = "";
        // localStorage.removeItem("Token");
        // localStorage.removeItem("userUID");
        // localStorage.removeItem("auditUID");
        // localStorage.removeItem("roleUID");
        // localStorage.removeItem('storeUID');
        // localStorage.removeItem('auditEntityUID');
        // localStorage.removeItem("firstName");
        // localStorage.removeItem("usertypeUID");
        // localStorage.removeItem('internetFlag');
        // localStorage.removeItem('newAudit');
        // localStorage.removeItem('auditType');
        // localStorage.removeItem('disableCheckBox');
        // localStorage.removeItem('roleType');
        // localStorage.removeItem('transactionUID');
        // localStorage.removeItem('closingMeetingAttendees');
        // localStorage.removeItem('actionPlan');
        // localStorage.removeItem('strictComments');
        // localStorage.removeItem('closingMeetingComments');
        // localStorage.removeItem('reviewBy');
        // localStorage.removeItem('reviewerValues');
        // localStorage.removeItem('actPlanRowData');
        // localStorage.removeItem('auditStatus');
        //return null;
    };    
    window.addEventListener("beforeunload", unloadCallback);
    return async () => {
      window.removeEventListener("beforeunload", unloadCallback);
    }
  }, []);
  var { isAuthenticated } = false;
  if (user)
  {
    isAuthenticated = true;
  }
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route path="/login" component={Login} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/login" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    if (localStorage.getItem('userUID') != null && localStorage.getItem('userUID') != 'null')
    {
      isAuthenticated = true;
    }
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
