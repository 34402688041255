import { APICore } from "./apiCore";
import {baseURL} from "./base";

const api = new APICore();

const apiPost=async  (apiName,params)=> {
    const base = `${baseURL}${apiName} ` ;
    return api.create(`${base}`, params);
}

const apiFormDataPost=async  (apiName,params)=> {
    const base = `${baseURL}${apiName} ` ;
    return api.createForm(`${base}`, params);
}

export {apiPost, apiFormDataPost};