//const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
//const imageURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_IMAGE_URL : process.env.REACT_APP_DEV_IMAGE_URL;

//uat
// const baseURL = 'https://ikeaaudit.bixware.app/IKEA_AUDIT/public/api';
// const imageURL = 'https://ikeaaudit.bixware.app/IKEA_AUDIT';

//dev
// const baseURL = 'https://ikeaaudit-dev.bixware.app/IKEA_AUDIT/public/api';
// const imageURL = 'https://ikeaaudit-dev.bixware.app/IKEA_AUDIT';

//live
const baseURL = 'https://ikeaaudit.uaenorth.cloudapp.azure.com/IKEA_AUDIT/public/api';
const imageURL = 'https://ikeaaudit.uaenorth.cloudapp.azure.com/IKEA_AUDIT';
export { baseURL, imageURL };