export const config = {
    login: "/login",
    getQuestions: "/getquestions",
    getAuditList:"/getauditlist",
    auditResponse : "/auditresponse",
    getReviewer: "/getreviewerlist",
    sentReview: "/sendforreview",
    sentAuditor:"/sendreviewerresponse",
    getAuditEntity: '/getauditentity',
    getInformation: '/getcontent/',
    editAuditData:'/editauditdata',
    updateAuditData: '/updateauditdata',
    //Food Safety
    getFAQuestions:'/FAgetquestions',
    faAuditResponse:'/FAauditresponse',
    faUpdateAuditResponse:'/FAupdateauditresponse',
    auditDetailsByID:'/getauditdetailsbyid',
    //Property Audit
    getPAQuestions:'/PAgetquestions',
    paAuditResponse:'/PAauditresponse',
    paAuditDetailsByID:'/PAgetauditdetailsbyid',
    paUpdateAuditResponse:'/PAupdateauditresponse',
    //DM Checklist
    getDMQuestions:'/DMgetquestions',
    dmAuditResponse:'/DMauditresponse',
    dmUpdateAuditResponse:'/DMupdateauditresponse',
    //Security and Safety Checklist
    getSSQuestions:'/SSgetquestions',
    ssAuditResponse:'/SSauditresponse',
    ssUpdateAuditResponse:'/SSupdateauditresponse',

    // Masters
    getcountry: '/getcountry',
    getstorebycountry: '/getstorebycountry',

    //Misc
    deleteAudit: '/deleteaudit',
    acceptAudit: '/acceptaudit',
    getAcceptAudit: '/getacceptaudit',

    //InsertAuditResponse
    insertFAAuditResponse : '/FAinsertauditresponse',
    paInsertAuditResponse : '/PAinsertauditresponse',
    dmInsertAuditResponse : '/DMinsertauditresponse',
    ssInsertAuditResponse : '/SSinsertauditresponse',

    //Reviewer Grid
    getfoodreviewquestions :'/getfoodreviewquestions',
    getdmreviewquestions: '/getdmreviewquestions',
    getpropertyreviewquestions : '/getpropertyreviewquestions',
    getsecurityreviewquestions : '/getsecurityreviewquestions',

    //Reviewer and Store User Action Plan
    updateactionplan : '/updateactionplan',
    markcomplete: '/markcomplete',
    reopenaudit: '/reopenaudit',
    uploadquestionimage : '/uploadquestionimage',
    addstoreusercomments: '/addstoreusercomments',
    completeaudit: '/completeaudit',
    sendreviewerresponsetoaudit:'/sendreviewerresponsetoaudit'

}
  