import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import { Box, Paper, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import TextField from "@mui/material/TextField";
import Select from '@mui/material/Select';
import { MenuItem } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import useStyles from "./style";
import { apiPost, apiFormDataPost } from "../../apiCommon";
import { config } from "../../config";
import 'react-dropzone-uploader/dist/styles.css'
import localforage from 'localforage';
import QuestionListForm from "./Template";
import { useHistory } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';
toast.configure({
    position: toast.POSITION.TOP_LEFT
});


const FoodAudit = localforage.createInstance({
    name: "IKEADB",
    storeName: "FoodAudit"
});


export default function QuestionForm(props) {
    var classes = useStyles();
    let history = useHistory();
    const locationState = props;
    const formRef = useRef();
    const divRef = useRef(null);
    const [questionValue, setQuestionValue] = React.useState(1);
    const [subQuestionValue, setSubQuestionValue] = React.useState(1);
    const [firstPage, setFirstPage] = React.useState('');
    const [firstSubPage, setFirstSubPage] = React.useState('');
    const [informationContent, setInformationContent] = React.useState('');
    const [lastPage, setLastPage] = React.useState('');
    const [isContentOpen, setIsContentOpen] = React.useState(false);
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [sectionId, setSectionId] = React.useState(0)
    const [questionsList, setQuestionsList] = React.useState([]);
    const [subQuestionsList, setSubQuestionsList] = React.useState([]);
    const [responseList, setResponseList] = React.useState([]);
    const [openQuestion, setOpenQuestion] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const [isBackDisabled, setIsBackDisabled] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({
        questions: []
    });

    const [selectedQuestion, setSelectedQuestion] = React.useState([]);
    const [selectedSubQuestionName, setSelectedSubQuestionName] = React.useState("")
    const [isBackOrSubmit, setIsBackOrSubmit] = React.useState("")
    const [isComment, setIsComment] = React.useState(false);
    const [isCriticalEnabled, setIsCriticalEnabled] = React.useState(false);
    const [isValidatePopup, setIsValidatePopup] = React.useState(false);
    const [isGeneralValidatePopup, setIsGeneralValidatePopup] = React.useState(false);
    const [dynamicQuestionsList, setDynamicQuestionsList] = React.useState([]);
    const [dynamicFinalAns, setDynamicFinalAns] = React.useState([]);
    const [questionPopupValues, setQuestionPopupValues] = React.useState("")
    const [isCheckedAll, setIsCheckedAll] = React.useState(false);
    const [informationPopup, setInformationPopup] = React.useState(false);
    const [isExitPopup, setIsExitPopup] = React.useState(false);
    const [isAllButtonsDisabled, setIsAllButtonsDisabled] = React.useState(false);
    const [submitPopUp, setSubmitPopUp] = React.useState(false);
    const [isCriticalAnswered, setIsCriticalAnswered] = React.useState(false)
    const [exitPopupMessage, setExitPopupMessage] = React.useState("");
    const [validationPopupMessage, setValidationPopupMessage] = React.useState("");
    const handleClosePopup = () => setIsValidatePopup(false);
    const handleGeneralValidateClosePopup = () => setIsGeneralValidatePopup(false);
    const handleCloseExitPopup = () => setIsExitPopup(false);
    const handleCloseSubmitPopup = () => setSubmitPopUp(false);


    React.useEffect(() => {
        (async () => {
            if (localStorage.getItem('auditType') == '1') {
                if (localStorage.getItem('internetFlag') == 'true') {
                    const response = await apiPost(config.getInformation + localStorage.getItem('auditType'));
                    setInformationContent(response.data.data[0].textData);
                }
            }
            // console.log(props)
            setQuestionsList(await FoodAudit.getItem("questionDetails"));
            const newFinalData = await FoodAudit.getItem("questionDetails");
            setFirstPage(newFinalData[0].auditTypeUID);
            setFirstSubPage(newFinalData[0].auditSubTypeQuestionDetails[0].auditSubtypeUID);
            setLastPage(newFinalData[newFinalData.length - 1].auditSubTypeQuestionDetails[newFinalData[newFinalData.length - 1].auditSubTypeQuestionDetails.length - 1].auditSubtypeUID);
            setQuestionValue(newFinalData[0].auditTypeUID);
            setSubQuestionValue(newFinalData[0].auditSubTypeQuestionDetails[0].auditSubtypeUID);
            localStorage.setItem('auditUID', newFinalData[0].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails[0].transactionUID)
            if (localStorage.getItem("isBackfromScoring") === true) {
                setIsCriticalEnabled(localStorage.getItem("isCritical"));
                setInformationPopup(false);
                setQuestionValue(newFinalData[newFinalData.length - 1].auditTypeUID);
                setSubQuestionsList(newFinalData[newFinalData.length - 1].auditSubTypeQuestionDetails)
                setSubQuestionValue(newFinalData[newFinalData.length - 1].auditSubTypeQuestionDetails[newFinalData[newFinalData.length - 1]?.auditSubTypeQuestionDetails.length - 1].auditSubtypeUID)
                setSelectedSubQuestionName(newFinalData[newFinalData.length - 1].auditSubTypeQuestionDetails[newFinalData[newFinalData.length - 1]?.auditSubTypeQuestionDetails.length - 1].auditSubtypeName);
                setSelectedQuestion(newFinalData[newFinalData.length - 1].auditSubTypeQuestionDetails[newFinalData[newFinalData.length - 1]?.auditSubTypeQuestionDetails.length - 1].auditSubtypeUIDDetails)
            }
            else {
                if (localStorage.getItem('auditType') == '1') {
                    let data = {
                        "auditUID": localStorage.getItem("auditUID")
                    }
                    const accepted = await apiPost(config.getAcceptAudit, data);
                    if (accepted.data.data[0].acceptStatus != '1') {
                        setInformationPopup(true);
                    }
                }
                setSubQuestionsList(newFinalData[0].auditSubTypeQuestionDetails)
                setSelectedSubQuestionName(newFinalData[0].auditSubTypeQuestionDetails[0].auditSubtypeName);
                setSelectedQuestion(newFinalData[0].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails)
            }
            // console.log(await FoodAudit.getItem("questionDetails"))

            setResponseList(await FoodAudit.getItem("answerresponseDetails"));

        })();
    }, [])

    React.useEffect(() => {
        if ((subQuestionValue).toString() === (lastPage).toString())
            setIsSubmit(true);
        else
            setIsSubmit(false);
        if ((questionValue).toString() === (firstPage).toString() && (subQuestionValue).toString() === (firstSubPage).toString())
            setIsBackDisabled(true);
        else
            setIsBackDisabled(false);
        allButtonsDisabled("2");
    }, [subQuestionValue, questionValue])

    function allButtonsDisabled(value) {
        let disableCheckBox = localStorage.getItem('disableCheckBox');
        if (value === "1") {
            if (isAllButtonsDisabled === true) {
                return true;
            } else {
                if (disableCheckBox == 'true')
                    return true;
                else
                    return false;
            }
        } else if (value === "2") {
            if (isAllButtonsDisabled === true) {
                return true;
            } else {
                if (isBackDisabled === true)
                    return true;
                else
                    return false;
            }

        } else if (value === "3") {
            if (isAllButtonsDisabled === true) {
                return true;
            } else
                return false;

        } else {
            return false;
        }
    }

    async function handleChange(event) {
        setQuestionValue(event.target.value);
        const question = questionsList.find((q) => q.auditTypeUID === event.target.value);
        setSubQuestionsList(question.auditSubTypeQuestionDetails);
        setSelectedSubQuestionName(question.auditSubTypeQuestionDetails[0].auditSubtypeName);
        setSelectedQuestion(question.auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails)
        setSubQuestionValue(question.auditSubTypeQuestionDetails[0].auditSubtypeUID)
        // console.log(question.auditSubTypeQuestionDetails);
        // console.log(dynamicFinalAns);
        setIsCheckedAll(false);
        if (localStorage.getItem("roleType") !== "Reviewer") {
            await ApiCall();
        }
        formRef.current.setFieldValue("questions", [])
        divRef.current.scroll({
            top: 0,
            behavior: "smooth"
        });
    };

    async function handleQuestionChange(event) {
        setSubQuestionValue(event.target.value);
        const selectedQuestionList = subQuestionsList.find((q) => q.auditSubtypeUID === event.target.value);
        setSelectedSubQuestionName(selectedQuestionList.auditSubtypeName);
        setSelectedQuestion(selectedQuestionList.auditSubtypeUIDDetails)
        // console.log(selectedQuestionList.auditSubtypeUIDDetails);
        // console.log(dynamicFinalAns);
        setIsCheckedAll(false);
        if (localStorage.getItem("roleType") !== "Reviewer") {
            await ApiCall();
        }
        formRef.current.setFieldValue("questions", [])
        divRef.current.scroll({
            top: 0,
            behavior: "smooth"
        });
    }

    async function closeModal() {
        let data = {
            "userUID": localStorage.getItem("userUID"),
            "auditUID": localStorage.getItem("auditUID"),
            "acceptStatus": "1"
        }
        const response = await apiPost(config.acceptAudit, data);
        if (response) {
            setInformationPopup(false);
        }
    }

    async function closeExitPopup() {
        await FoodAudit.removeItem("answerresponseDetails");
        await FoodAudit.removeItem("questionDetails");
        await FoodAudit.removeItem("storedAnswers");
        history.push({ pathname: "/app/dashboard" });
    }

    async function okSubmitPopup() {
        if (localStorage.getItem('internetFlag') == 'true') {
            if (localStorage.getItem("roleType") !== "Reviewer") {
                setLoading(true);
                await ApiCall();
                setLoading(false);
            }
        }
        else {
            if (localStorage.getItem("roleType") !== "Reviewer") {
                await ApiCall();
            }
        }
        var isCritical = (isCriticalEnabled === true || isCriticalAnswered === true) ? true : false;
        localStorage.setItem("isBackfromScoring", false);
        history.push({ pathname: "/app/ui/scoringtable", state: { questions: questionsList, response: responseList, isCritical: isCritical } })
        setSubmitPopUp(false)
    }

    async function ApiCall() {
        if (localStorage.getItem('disableCheckBox') != 'true') {
            let finalAns = [];
            let storedAnswers = await FoodAudit.getItem("storedAnswers") === null ? [] : await FoodAudit.getItem("storedAnswers");
            let userUID = localStorage.getItem("userUID")
            let response;
            const form_data = new FormData();
            if (dynamicFinalAns) {
                // console.log(dynamicFinalAns);
                dynamicFinalAns.forEach((q3, index) => {
                    if (localStorage.getItem('auditType') == '2') {
                        finalAns.push({
                            "userUID": userUID,
                            "valuation": q3.valuation,
                            "comments": q3.comments,
                            "auditSubtypeUID": q3.auditSubtypeUID,
                            "observationUID": q3.observationUID,
                            "questionnaireUID": q3.questionnaireUID,
                            "transactionUID": q3.transactionUID,
                            "images": q3.images,
                            "videos": q3.videos,

                        })
                    } else
                        if (localStorage.getItem('auditType') == '3') {
                            finalAns.push({
                                "userUID": userUID,
                                "valuation": q3.valuation,
                                "comments": q3.comments,
                                "observationUID": q3.observationUID,
                                "questionnaireUID": q3.questionnaireUID,
                                "transactionUID": q3.transactionUID,
                                "images": q3.images,
                                "videos": q3.videos,

                            })
                        } else
                            if (localStorage.getItem('auditType') == '4') {
                                finalAns.push({
                                    "userUID": userUID,
                                    "valuation": q3.valuation,
                                    "comments": q3.comments,
                                    "observationUID": q3.observationUID,
                                    "questionnaireUID": q3.questionnaireUID,
                                    "transactionUID": q3.transactionUID,
                                    "images": q3.images,
                                    "videos": q3.videos,

                                })
                            }
                            else {
                                finalAns.push({
                                    "userUID": userUID,
                                    "valuation": q3.valuation,
                                    "comments": q3.comments,
                                    "status": checkStatusStandard(q3.valuation ? q3.valuation : ""),
                                    "observationUID": q3.observationUID,
                                    "questionnaireUID": q3.questionnaireUID,
                                    "transactionUID": q3.transactionUID,
                                    "images": q3.images,
                                    "videos": q3.videos,

                                })
                            }
                })


            } else {
                setDynamicFinalAns([]);
            }

            if (storedAnswers) {
                if (storedAnswers.length > 0) {
                    storedAnswers.forEach((sAns, sIndex) => {
                        if (localStorage.getItem('auditType') == '2') {
                            finalAns.push({
                                "userUID": userUID,
                                "valuation": sAns.valuation,
                                "comments": sAns.comments,
                                "auditSubtypeUID": sAns.auditSubtypeUID,
                                "observationUID": sAns.observationUID,
                                "questionnaireUID": sAns.questionnaireUID,
                                "transactionUID": sAns.transactionUID,
                                "images": sAns.images,
                                "videos": sAns.videos,

                            })
                        } else
                            if (localStorage.getItem('auditType') == '3') {
                                finalAns.push({
                                    "userUID": userUID,
                                    "valuation": sAns.valuation,
                                    "comments": sAns.comments,
                                    "observationUID": sAns.observationUID,
                                    "questionnaireUID": sAns.questionnaireUID,
                                    "transactionUID": sAns.transactionUID,
                                    "images": sAns.images,
                                    "videos": sAns.videos,

                                })
                            } else
                                if (localStorage.getItem('auditType') == '4') {
                                    finalAns.push({
                                        "userUID": userUID,
                                        "valuation": sAns.valuation,
                                        "comments": sAns.comments,
                                        "observationUID": sAns.observationUID,
                                        "questionnaireUID": sAns.questionnaireUID,
                                        "transactionUID": sAns.transactionUID,
                                        "images": sAns.images,
                                        "videos": sAns.videos,

                                    })
                                }
                                else {
                                    finalAns.push({
                                        "userUID": userUID,
                                        "valuation": sAns.valuation,
                                        "comments": sAns.comments,
                                        "status": checkStatusStandard(sAns.valuation ? sAns.valuation : ""),
                                        "observationUID": sAns.observationUID,
                                        "questionnaireUID": sAns.questionnaireUID,
                                        "transactionUID": sAns.transactionUID,
                                        "images": sAns.images,
                                        "videos": sAns.videos,

                                    })
                                }
                    })
                }
            }


            if (localStorage.getItem('internetFlag') == 'true') {
                if (finalAns.length > 0) {
                    finalAns.forEach((key, index) => {
                        if (index == 0) {
                            form_data.append('userUID', key.userUID);
                            form_data.append('transactionUID', key.transactionUID);
                        }
                        if (localStorage.getItem('auditType') == '2') {
                            form_data.append('auditSubtypeUID[]', key.auditSubtypeUID);
                        }
                        form_data.append('valuation[]', key.valuation === null ? '' : key.valuation);
                        form_data.append('comments[]', key.comments === null || key.comments === 'null' ? '' : key.comments);
                        form_data.append('status[]', key.status === null ? '' : key.status);
                        form_data.append('observationUID[]', key.observationUID);
                        form_data.append('questionnaireUID[]', key.questionnaireUID);
                        if (finalAns[index].images.length > 0) {
                            for (let i = 0; i < finalAns[index].images.length; i++) {
                                form_data.append(`images_${key.questionnaireUID}[]`, finalAns[index].images[i]);
                            }
                        } else {
                            form_data.append(`images_${key.questionnaireUID}[]`, []);
                        }
                        if (finalAns[index].videos.length > 0) {
                            for (let i = 0; i < finalAns[index].videos.length; i++) {
                                form_data.append(`videos_${key.questionnaireUID}[]`, finalAns[index].videos[i]);
                            }
                        } else {
                            form_data.append(`videos_${key.questionnaireUID}[]`, []);
                        }
                    });
                }
                //apicall
                if (storedAnswers.length > 0 || dynamicFinalAns.length > 0) {
                    let buttonsArr = ["1", "2", "3"];
                    setIsAllButtonsDisabled(true);
                    buttonsArr.forEach((button) => allButtonsDisabled(button));
                    if (localStorage.getItem('auditType') == '2') {
                        response = await apiFormDataPost(config.paInsertAuditResponse, form_data);
                    } else if (localStorage.getItem('auditType') == '3') {
                        response = await apiFormDataPost(config.dmInsertAuditResponse, form_data);
                    } else if (localStorage.getItem('auditType') == '4') {
                        response = await apiFormDataPost(config.ssInsertAuditResponse, form_data);
                    } else {
                        response = await apiFormDataPost(config.insertFAAuditResponse, form_data);
                    }
                    if (response.data.data[0].status === "Updated Successfully") {
                        setIsAllButtonsDisabled(false);
                        buttonsArr.forEach((button) => allButtonsDisabled(button));
                        await FoodAudit.removeItem("storedAnswers");
                        setDynamicFinalAns([]);
                    } else {
                        setIsAllButtonsDisabled(false);
                        buttonsArr.forEach((button) => allButtonsDisabled(button));
                        if (dynamicFinalAns.length > 0) {
                            dynamicFinalAns.forEach((dyFinalAns) => {
                                storedAnswers.push(dyFinalAns);
                            })
                        }
                        await FoodAudit.setItem("storedAnswers", storedAnswers);
                    }
                }
            } else {
                if (dynamicFinalAns.length > 0) {
                    dynamicFinalAns.forEach((dyFinalAns) => {
                        storedAnswers.push(dyFinalAns);
                    })
                }
                if (dynamicFinalAns.length > 0) {
                    await FoodAudit.setItem("storedAnswers", storedAnswers);
                }
                setDynamicFinalAns([]);
            }
        }

    }

    const checkStatusStandard = (value) => {
        switch (value) {
            case '1':
                return (
                    "standard"
                );
            case '2':
                return (
                    "standard"
                );
            case '3':
                return (
                    "below critical"
                );
            case '4':
                return (
                    "serious"
                );
            case '5':
                return (
                    "critical"
                );
            case '':
                return (
                    "Not applicable"
                );
            default:
                throw new Error("Unknown step");
        }
    }

    async function handleSubmit(values, props) {
        if (isBackOrSubmit === "submit") {
            submitButtonClicked(values, props)
        }
        else if (isBackOrSubmit === "back") {
            backButtonClicked(values, props)
            setIsSubmit(false);
        }
        else if (isBackOrSubmit === "exit") {
            exitButtonClicked(values, props)
        }
    }

    async function exitButtonClicked(values, props) {
        setIsContentOpen(false);
        // console.log(values);
        let tempList = questionsList;
        answeredQuestions(values);
        setIsValidatePopup(false);
        setIsCheckedAll(false);
        let finalAns = [];
        let userUID = localStorage.getItem("userUID")
        tempList.forEach((q1, i1) => {
            q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                    if (q3.valuation !== "") {
                        if (localStorage.getItem('auditType') == '2') {
                            finalAns.push({
                                "userUID": userUID,
                                "valuation": q3.valuation,
                                "comments": q3.comments,
                                "auditSubtypeUID": q3.auditSubtypeUID,
                                "observationUID": q3.observationUID,
                                "questionnaireUID": q3.questionnaireUID,
                                "transactionUID": q3.transactionUID,
                                "images": q3.images,
                                "videos": q3.videos,

                            })
                        }
                        else
                            if (localStorage.getItem('auditType') == '3' || localStorage.getItem('auditType') == '4') {
                                finalAns.push({
                                    "userUID": userUID,
                                    "valuation": q3.valuation,
                                    "comments": q3.comments,
                                    "observationUID": q3.observationUID,
                                    "questionnaireUID": q3.questionnaireUID,
                                    "transactionUID": q3.transactionUID,
                                    "images": q3.images,
                                    "videos": q3.videos,

                                })
                            }
                            else {
                                finalAns.push({
                                    "userUID": userUID,
                                    "valuation": q3.valuation,
                                    "comments": q3.comments,
                                    "status": checkStandard(q3.valuation ? q3.valuation : ""),
                                    "observationUID": q3.observationUID,
                                    "questionnaireUID": q3.questionnaireUID,
                                    "transactionUID": q3.transactionUID,
                                    "images": q3.images,
                                    "videos": q3.videos,

                                })
                            }

                    }
                })
            })
        });

        if (localStorage.getItem('internetFlag') == 'true') {
            if (localStorage.getItem("roleType") !== "Reviewer") {
                await ApiCall();
            }
            setExitPopupMessage("Are you sure you want to exit?");
            // history.push({ pathname: "/app/dashboard" });
        } else {
            setExitPopupMessage("Datas has not been saved! Are you sure you want to exit?");
        }
        setIsExitPopup(true);

    }

    async function backButtonClicked(values, props) {
        setIsContentOpen(false);
        // console.log(values);
        let tempList = questionsList;
        answeredQuestions(values);
        var validation = validationCheck(values);
        if (validation === true) {
            return
        }
        var qustList = questionsList;
        setIsValidatePopup(false);
        setIsCheckedAll(false);
        // if (localStorage.getItem('auditType') !== '1') {
            setValidationPopupMessage("Please answer all the questions to proceed further")
            var commonValidation = false;
            var commonValidateCount = 0;
            qustList.forEach((q1, i1) => {
                if (q1.auditTypeUID.toString() === questionValue.toString()) {
                    q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                        if (q2.auditSubtypeUID.toString() === subQuestionValue.toString()) {
                            q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                                if (q3.valuation === null || q3.valuation === "") {
                                    commonValidateCount++;
                                }
                                if (commonValidateCount > 0) {
                                    commonValidation = true;
                                } else {
                                    commonValidation = false;
                                }
                            })
                        }
                    })
                }
            })
        // }
        if (commonValidation) {
            setIsGeneralValidatePopup(true);
            return;
        }
        let criticalCount = 0
        tempList.forEach((q1, i1) => {
            q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                    if (q3.questionCategory === "Critical") {
                        if (q3.valuation === "4") {
                            criticalCount++;
                            setIsCriticalEnabled(true);
                            return
                        }
                    }

                })
            })
        })
        if (criticalCount === 0) {
            setIsCriticalEnabled(false);
        }

        // console.log(tempList)
        setQuestionsList(tempList);
        if (localStorage.getItem("roleType") !== "Reviewer") {
            await ApiCall();
        }
        await FoodAudit.setItem("questionDetails", questionsList)

        let indexOfQuestionList;
        let indexOfSubQuestionList;
        questionsList.forEach((q, i) => {
            if (q.auditTypeUID === questionValue.toString()) {
                indexOfQuestionList = i;
                return;
            }
        })
        subQuestionsList.forEach((q, i) => {
            if (q.auditSubtypeUID === subQuestionValue) {
                indexOfSubQuestionList = i;
                if (indexOfSubQuestionList !== 0) {
                    setSelectedSubQuestionName(questionsList[indexOfQuestionList].auditSubTypeQuestionDetails[indexOfSubQuestionList - 1].auditSubtypeName)
                    setSelectedQuestion(questionsList[indexOfQuestionList].auditSubTypeQuestionDetails[indexOfSubQuestionList - 1].auditSubtypeUIDDetails)
                    setSubQuestionValue(questionsList[indexOfQuestionList].auditSubTypeQuestionDetails[indexOfSubQuestionList - 1].auditSubtypeUID)
                    props.setFieldValue("questions", [])
                    setInitialValues({
                        questions: [
                        ]
                    })
                }
                else {
                    setQuestionValue(questionsList[indexOfQuestionList - 1].auditTypeUID);
                    setSubQuestionsList(questionsList[indexOfQuestionList - 1].auditSubTypeQuestionDetails);
                    setSelectedSubQuestionName(questionsList[indexOfQuestionList - 1].auditSubTypeQuestionDetails[questionsList[indexOfQuestionList - 1].auditSubTypeQuestionDetails.length - 1].auditSubtypeName);
                    setSelectedQuestion(questionsList[indexOfQuestionList - 1].auditSubTypeQuestionDetails[questionsList[indexOfQuestionList - 1].auditSubTypeQuestionDetails.length - 1].auditSubtypeUIDDetails)
                    setSubQuestionValue(questionsList[indexOfQuestionList - 1].auditSubTypeQuestionDetails[questionsList[indexOfQuestionList - 1].auditSubTypeQuestionDetails.length - 1].auditSubtypeUID)
                    props.setFieldValue("questions", [])
                    setInitialValues({
                        questions: [
                        ]
                    })
                }
                divRef.current.scroll({
                    top: 0,
                    behavior: "smooth"
                });
                return;
            }
        })
    }

    async function submitButtonClicked(values, props) {
        setIsContentOpen(false);
        // console.log(values);

        let tempList = questionsList;
        answeredQuestions(values);
        var validation = validationCheck(values);
        if (validation === true) {
            if (!isSubmit) {
            return
            }
        }
        var qustList = questionsList;
        setIsValidatePopup(false);
        setIsCheckedAll(false);


        // if (localStorage.getItem('auditType') !== '1') {
            setValidationPopupMessage("Please answer all the questions to proceed further")
            var commonValidation = false;
            var commonValidateCount = 0;
            qustList.forEach((q1, i1) => {
                if (q1.auditTypeUID.toString() === questionValue.toString()) {
                    q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                        if (q2.auditSubtypeUID.toString() === subQuestionValue.toString()) {
                            q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                                if (q3.valuation === null || q3.valuation === "") {
                                    commonValidateCount++;
                                }
                                if (commonValidateCount > 0) {
                                    commonValidation = true;
                                } else {
                                    commonValidation = false;
                                }
                            })
                        }
                    })
                }
            })
            var wholeValidation = false;
            var wholeValidateCount = 0;
            qustList.forEach((q1, i1) => {
                q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                    q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                        if (q3.valuation === null || q3.valuation === "") {
                            wholeValidateCount++;
                        }
                        if (wholeValidateCount > 0) {
                            wholeValidation = true;
                        } else {
                            wholeValidation = false;
                        }
                    })
                })
            })
            if (isSubmit) {
                if (localStorage.getItem("roleType") !== "Reviewer" && localStorage.getItem("roleType") !== "Store User") {
                    commonValidation = false;
                    if (wholeValidation) {
                        setIsGeneralValidatePopup(true);
                        return;
                    }
                }
            }
            if (commonValidation) {
                setIsGeneralValidatePopup(true);
                return;
            }
        // }

        const ans = responseList;

        let criticalCount = 0
        tempList.forEach((q1, i1) => {
            q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                    if (q3.questionCategory === "Critical") {
                        if (q3.valuation === "4") {
                            criticalCount++;
                            setIsCriticalEnabled(true);
                            setIsCriticalAnswered(true)
                            return
                        }
                    }

                })
            })
        })

        if (criticalCount === 0) {
            setIsCriticalEnabled(false);
            setIsCriticalAnswered(false)
        }

        // console.log(tempList)
        setQuestionsList(tempList);
        if (!isSubmit) {
            if (localStorage.getItem("roleType") !== "Reviewer" && localStorage.getItem("roleType") !== "Store User") {
                await ApiCall();
            }
        }
        await FoodAudit.setItem("questionDetails", questionsList)
        await FoodAudit.setItem("answerDetails", responseList)
        localStorage.setItem("transactionUID", selectedQuestion[0].transactionUID);
        if (isSubmit) {
            if (localStorage.getItem("roleType") === "Reviewer" && localStorage.getItem("roleType") === "Store User") {
                var isCritical = (isCriticalEnabled === true || isCriticalAnswered === true) ? true : false;
                history.push({ pathname: "/app/ui/scoringtable", state: { questions: questionsList, response: responseList, isCritical: isCritical } })
            }
            else {
                setSubmitPopUp(true)
            }
            // var isCritical = (isCriticalEnabled === true || isCriticalAnswered === true) ? true : false;
            // history.push({ pathname: "/app/ui/scoringtable", state: { questions: questionsList, response: responseList, isCritical: isCritical } })
            return;
        }

        let indexOfQuestionList;
        let indexOfSubQuestionList;
        questionsList.forEach((q, i) => {
            if (q.auditTypeUID === questionValue.toString()) {
                indexOfQuestionList = i;
                return;
            }
        })

        subQuestionsList.forEach((q, i) => {
            if (q.auditSubtypeUID === subQuestionValue) {
                indexOfSubQuestionList = i;
                if (subQuestionsList.length !== i + 1) {
                    setSelectedSubQuestionName(questionsList[indexOfQuestionList].auditSubTypeQuestionDetails[indexOfSubQuestionList + 1].auditSubtypeName)
                    setSelectedQuestion(questionsList[indexOfQuestionList].auditSubTypeQuestionDetails[indexOfSubQuestionList + 1].auditSubtypeUIDDetails)
                    setSubQuestionValue(questionsList[indexOfQuestionList].auditSubTypeQuestionDetails[indexOfSubQuestionList + 1].auditSubtypeUID)
                    props.setFieldValue("questions", [])
                    setInitialValues({
                        questions: [
                        ]
                    })
                }
                else if (questionsList.length !== indexOfQuestionList + 1) {
                    setQuestionValue(questionsList[indexOfQuestionList + 1].auditTypeUID);
                    setSubQuestionsList(questionsList[indexOfQuestionList + 1].auditSubTypeQuestionDetails);
                    setSelectedSubQuestionName(questionsList[indexOfQuestionList + 1].auditSubTypeQuestionDetails[0].auditSubtypeName);
                    setSelectedQuestion(questionsList[indexOfQuestionList + 1].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails)
                    setSubQuestionValue(questionsList[indexOfQuestionList + 1].auditSubTypeQuestionDetails[0].auditSubtypeUID)
                    props.setFieldValue("questions", [])
                    setInitialValues({
                        questions: [
                        ]
                    })
                }
                divRef.current.scroll({
                    top: 0,
                    behavior: "smooth"
                });
                return;
            }
        })
    }

    function answeredQuestions(values) {
        let tempList = questionsList;

        tempList.forEach((q1, i1) => {
            if (q1.auditTypeUID.toString() === questionValue.toString()) {
                q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                    if (q2.auditSubtypeUID.toString() === subQuestionValue.toString()) {
                        q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                            q3.valuation = values?.questions[i]?.valuation ? values.questions[i].valuation : q3.valuation;
                            q3.comments = values?.questions[i]?.comments ? values.questions[i].comments : q3.comments;
                            q3.images = values?.questions[i]?.images ? values.questions[i].images : q3.images;
                            q3.videos = values?.questions[i]?.videos ? values.questions[i].videos : q3.videos;
                            if (localStorage.getItem('auditType') != '3')
                                q3.standard = checkStandard(values?.questions[i]?.valuation ? values.questions[i].valuation : "");

                        })
                    }
                })
            }
        })
    }

    function validationCheck(values) {
        var isValidate = false;
        var qustList = questionsList;
        if (localStorage.getItem('auditType') == '1') {
            setValidationPopupMessage("Please answer all the General section questions to proceed further (N/A is not a valid answer)")
            var validationArr = [];

            var generalValidation = false;
            var generalValidateCount = 0;
            qustList.forEach((q1, i1) => {
                if (q1.auditTypeUID.toString() === questionValue.toString()) {
                    q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                        if (q2.auditSubtypeUID.toString() === subQuestionValue.toString()) {
                            q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                                if (q1.auditTypeUID === "1" || q1.auditTypeUID === "2") {
                                    if ((q3.auditSubtypeName).toLowerCase() === "general") {
                                        if (q3.valuation === null || q3.valuation === "5" || q3.valuation === "") {
                                            generalValidateCount++;
                                        }
                                    }
                                }
                                if (generalValidateCount > 0) {
                                    generalValidation = true;
                                } else {
                                    generalValidation = false;
                                }

                                if (values?.questions[i]?.valuation === "2" || values?.questions[i]?.valuation === "3" || values?.questions[i]?.valuation === "4") {
                                    if (values?.questions[i]?.valuation === "2") {
                                        if (values?.questions[i]?.comments === undefined || values?.questions[i]?.comments === "") {
                                            if (q3.comments === "" || q3.comments === null || q3.comments === undefined) {
                                                validationArr.push(i);
                                            }
                                        }
                                    }
                                    else
                                        if (values?.questions[i]?.valuation === "3") {
                                            if (values?.questions[i]?.comments === undefined || values?.questions[i]?.comments === "") {
                                                if (q3.comments === "" || q3.comments === null || q3.comments === undefined) {
                                                    validationArr.push(i);
                                                }
                                            }
                                        }
                                        else
                                            if (values?.questions[i]?.valuation === "4") {
                                                if (values?.questions[i]?.comments === undefined || values?.questions[i]?.comments === "") {
                                                    if (q3.comments === "" || q3.comments === null || q3.comments === undefined) {
                                                        validationArr.push(i);
                                                    }
                                                }
                                            }
                                }

                            })
                        }
                    })
                }
            });
            var totalGeneralValidation = false;
            var totalGeneralValidateCount = 0;
            qustList.forEach((q1, i1) => {
                if (q1.auditTypeUID === "1" || q1.auditTypeUID === "2") {
                    q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                        q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                            if ((q3.auditSubtypeName).toLowerCase() === "general") {
                                if (q3.valuation === null || q3.valuation === "5" || q3.valuation === "") {
                                    totalGeneralValidateCount++;
                                }
                            }
                            if (totalGeneralValidateCount > 0) {
                                totalGeneralValidation = true;
                            } else {
                                totalGeneralValidation = false;
                            }
                        })
                    })
                }
            })
            if (validationArr.length > 0) {
                var arrStr = "";
                validationArr.forEach((v, i) => {
                    arrStr += JSON.stringify(v + 1);
                    if (i !== (validationArr.length - 1)) {
                        arrStr += ","
                    }
                })
                setQuestionPopupValues(arrStr);
                isValidate = true;
            }
            else
                isValidate = false;

            if (generalValidation) {
                setIsGeneralValidatePopup(true);
                return true;
            }
            if (isSubmit) {
                if (localStorage.getItem("roleType") !== "Reviewer") {
                    if (totalGeneralValidation) {
                        setIsGeneralValidatePopup(true);
                        return true;
                    }
                }
            }

            if (isValidate) {
                setIsValidatePopup(true);
                return true;
            }
        }
        return false;
    }

    const handleBackSubmitCheck = (value) => {
        if (value === "back")
            setIsBackOrSubmit("back")
        else if (value === "submit")
            setIsBackOrSubmit("submit")
        else if (value === "exit")
            setIsBackOrSubmit("exit")
    }

    const checkStandard = (value) => {
        switch (value) {
            case '1':
                return (
                    "standard"
                );
            case '2':
                return (
                    "standard"
                );
            case '3':
                return (
                    "below critical"
                );
            case '4':
                return (
                    "serious"
                );
            case '5':
                return (
                    "critical"
                );
            case '':
                return (
                    "Not applicable"
                );
            default:
                throw new Error("Unknown step");
        }
    }

    // const validationSchema = 
    //     Yup.object().shape({
    //         questions: Yup.array().of(
    //           Yup.object().shape({
    //             valuation: Yup.string().required("Required"),
    //           })
    //         ),
    //       })


    function getTemplate(selectedQuestion, responseList, props) {
        setDynamicQuestionsList(selectedQuestion);
        return (
            <QuestionListForm
                selectedQuestion={selectedQuestion}
                responseList={responseList}
                props={props}
                isCheckedAll={isCheckedAll}
                setIsCheckedAll={setIsCheckedAll}
                isContentOpen={isContentOpen}
                setIsContentOpen={setIsContentOpen}
                dynamicQuestionsList={dynamicQuestionsList}
                setDynamicQuestionsList={setDynamicQuestionsList}
                setDynamicFinalAns={setDynamicFinalAns}
            />
        )
    }

    function goToDashboard() {
        if (localStorage.getItem('internetFlag') == 'true') {
            history.push({ pathname: "/app/dashboard" });
        } else {
            toast.error("You are not online to go to Dashboard");
        }
    }
    function goToReport() {
        let criticalQuestion = false;
        if (localStorage.getItem('auditEntityUID') === '1') {
            questionsList.forEach((q1, i1) => {
                q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                    q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                        if (q3.questionCategory === "Critical") {
                            if (q3.valuation === "4") {
                                criticalQuestion = true;
                                return
                            }
                        }
                        if (criticalQuestion === true)
                            return
                    })
                    if (criticalQuestion === true)
                        return
                })
                if (criticalQuestion === true)
                    return
            })
        }

        // if (localStorage.getItem('internetFlag') == 'true') {
        var isCritical = (criticalQuestion === true) ? true : false;
        history.push({ pathname: "/app/ui/scoringtable", state: { questions: questionsList, response: responseList, isCritical: isCritical } })
        // } else {
        //     toast.error("You are not online to go to Report");
        // }
    }

    return (
        <>
            {loading ? (
                <>
                    <ThreeDots

                        color="#00BFFF"
                        height={100}
                        width={100}
                        wrapperStyle={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "13rem"
                        }}
                    />
                </>
            ) : (
                <>
                    <Box className={classes.container} sx={{ paddingTop: "70px", }}>
                        <Box sx={{ width: '100%', backgroundColor: "white", borderRadius: "5px" }}>

                            {/* <Grid container sx={{ backgroundColor: "#00319c" }}> */}
                            <Grid container>
                            <Grid item xs={12} sx={{ paddingRight:"0px" , paddingLeft:"0px"}}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    // Change the size to fit the parent element of this div
                                    width: '100%',
                                    height: '100%',
                                    padding: "5px",
                                    backgroundColor: "white",
                                }}>
                                    {/* <Paper elevation={10} sx={{ width: "100%" }}> */}
                                    <Select className="question_top w-100"
                                        value={questionValue}
                                        onChange={handleChange}
                                        sx={{
                                            backgroundColor: "deepskyblue",
                                            boxShadow: "none",
                                            color:"white",
                                            ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": { padding: "8.5px 14px !important" },
                                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                        }}
                                    >
                                        {questionsList?.map((option) => (
                                            <MenuItem sx={{ whiteSpace: 'normal' }} className="question_item" key={option.auditTypeUID} value={option.auditTypeUID}>
                                                {option.auditTypeName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* </Paper> */}
                                </div>
                            </Grid>
                            {/* <Grid item xs={3} sx={{ paddingRight: "2rem", color: "#ffce00" }} className="align-self-center text-end">                            
                                <Tooltip title="Info"><InfoIcon sx={{ float: 'right', cursor: 'pointer', display: localStorage.getItem('auditType') == '1' ? 'none' : 'none' }} onClick={() => setInformationPopup(true)} /></Tooltip>
                            </Grid> */}
                            </Grid>
                            {/* <Grid item xs={2}>
                            <Typography variant="h6">
                                {(selectedQuestion?.questions.length) - 2} / {selectedQuestion?.questions.length}
                            </Typography>
                        </Grid> */}
                            {/* </Grid> */}
                            {isComment ?
                                (
                                    <Box sx={{ width: '100%', backgroundColor: "#00319c1f", borderRadius: "5px", marginTop: "0.85rem", boxShadow: "0px 2px 0px 1px #1976d236" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    multiline
                                                    rows={3}
                                                    variant="outlined"
                                                    fullWidth
                                                    sx={{
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px"
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button classes={{ root: classes.button }}
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    sx={{
                                                        marginLeft: "10px",
                                                        marginBottom: "10px"
                                                    }}
                                                >Save</Button>
                                            </Grid>
                                        </Grid>
                                    </Box>) : null}
                            <Grid container>
                                <Grid item xs={12} sx={{ paddingRight:"0px" , paddingLeft:"0px"}}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    // Change the size to fit the parent element of this div
                                    width: '100%',
                                    height: '100%',
                                    padding: "5px",
                                    backgroundColor: "white",
                                }}>
                                    <Select className="question_top w-100"
                                        value={subQuestionValue}
                                        onChange={handleQuestionChange}
                                        sx={{
                                            backgroundColor: "silver",
                                            boxShadow: "none",
                                            ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": { padding: "8.5px 14px !important" },
                                            ".MuiOutlinedInput-notchedOutline": { border: 0, color: "#fff" },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0, color: "#fff"
                                            },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0, color: "#fff"
                                            }
                                        }}
                                    >
                                        {subQuestionsList.map((option) => (
                                            <MenuItem sx={{ whiteSpace: 'normal' }} key={option.auditSubtypeUID} value={option.auditSubtypeUID}>
                                                {option.auditSubtypeName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className="quetio_main">
                                    <Box sx={{ backgroundColor: "#f2f5f5", overflowY: 'scroll' }} id="accordionStyle" ref={divRef}>
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={handleSubmit}
                                            enableReinitialize={true}
                                            // validationSchema={validationSchema}
                                            innerRef={formRef}
                                        >
                                            {(props) => (
                                                <div>
                                                    <Form>
                                                        <React.Fragment>
                                                            <Box>
                                                                <Grid container sx={{marginBottom:"10px"}}>
                                                                    {
                                                                        (localStorage.getItem("roleType") === "Reviewer" || localStorage.getItem("roleType") === "Store User" || localStorage.getItem("auditStatus") === "Audit Completed") ? (
                                                                            <>
                                                                                <Grid item lg={8} md={6} sm={6} xs={12} sx={{ justifyContent: "end", display: "flex" }}>
                                                                                    <Button size="lg" variant="contained" disabled={allButtonsDisabled("4")} onClick={goToDashboard}
                                                                                     sx={{ background: "#ff2200", marginTop: '10px',
                                                                                     '&:hover': {
                                                                                        background: "#0257a7",
                                                                                    }, }} >
                                                                                        Go to Dashboard
                                                                                    </Button>
                                                                                </Grid>
                                                                                <Grid className="goto_reports" item lg={2} md={3} sm={4} xs={12} sx={{ justifyContent: "end", display: "flex" }}>
                                                                                    <Button size="lg" variant="contained" disabled={allButtonsDisabled("4")} onClick={goToReport}
                                                                                     sx={{ background: "#ff2200", marginTop: '10px' ,
                                                                                     '&:hover': {
                                                                                        background: "#0257a7",
                                                                                    },}} >
                                                                                        Go to Report
                                                                                    </Button>
                                                                                </Grid>
                                                                            </>
                                                                        ) : null
                                                                    }
                                                                    {(localStorage.getItem("roleType") !== "Reviewer" && localStorage.getItem("roleType") !== "Store User" && localStorage.getItem("auditStatus") !== "Audit Completed") ? (
                                                                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "end", display: "flex" }}>
                                                                            <Button size="lg" type="submit" variant="contained" disabled={allButtonsDisabled("1")} onClick={() => { handleBackSubmitCheck("exit") }}
                                                                             sx={{ background: "#ff2200", marginTop: '10px',
                                                                             '&:hover': {
                                                                                background: "#0257a7",
                                                                            }, }} >
                                                                                Exit Audit
                                                                            </Button>
                                                                        </Grid>) : (
                                                                        <Grid className="exit_audit" item lg={2} md={3} sm={2} xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                                                                            <Button size="lg" type="submit" variant="contained" disabled={allButtonsDisabled("1")} onClick={() => { handleBackSubmitCheck("exit") }}
                                                                             sx={{ background: "#ff2200", marginTop: '10px',
                                                                             '&:hover': {
                                                                                background: "#0257a7",
                                                                            }, }} >
                                                                                Exit Audit
                                                                            </Button>
                                                                        </Grid>
                                                                    )}

                                                                </Grid>
                                                                {getTemplate(selectedQuestion, responseList, props)}
                                                                <Grid container rowSpacing={1} sx={{ justifyContent: "space-between", }} id="backSubmitButtons">
                                                                    <Grid item xs={2}>
                                                                        <Button size="lg" type="submit" variant="contained" disabled={allButtonsDisabled("2")} onClick={() => { handleBackSubmitCheck("back") }} sx={{ background: "#ff2200", }} >
                                                                            Back
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={4} sx={{ textAlign: "end", }}>
                                                                        <Button size="lg" type="submit"  classes={{ root: classes.button }} variant="contained" disabled={allButtonsDisabled("3")} onClick={() => { handleBackSubmitCheck("submit") }}>
                                                                            {isSubmit ? "Submit" : "Next"}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </React.Fragment>
                                                    </Form>
                                                    <Box>
                                                        <Modal
                                                            open={isValidatePopup}
                                                            onClose={handleClosePopup}
                                                            aria-labelledby="title"
                                                            aria-describedby="description"
                                                        >
                                                            <Box className={classes.popupStyle}>
                                                                <Typography id="title" variant="h6" component="h2">
                                                                    Warning for comments!
                                                                </Typography>
                                                                <Typography id="description" sx={{ mt: 2 }}>
                                                                    Please enter the comments for the questions {questionPopupValues} to proceed further
                                                                </Typography>
                                                                <Button size="md" type="button"  classes={{ root: classes.button }} variant="contained" onClick={handleClosePopup}>
                                                                    Ok
                                                                </Button>
                                                            </Box>
                                                        </Modal>
                                                    </Box>
                                                    <Box>
                                                        <Modal
                                                            open={isGeneralValidatePopup}
                                                            onClose={handleGeneralValidateClosePopup}
                                                            aria-labelledby="title"
                                                            aria-describedby="description"
                                                        >
                                                            <Box className={classes.popupStyle}>
                                                                <Typography id="title" variant="h6" component="h2">
                                                                    Warning
                                                                </Typography>
                                                                <Typography id="description" sx={{ mt: 2 }}>
                                                                    {validationPopupMessage}
                                                                </Typography>
                                                                <Button size="md" type="button"  classes={{ root: classes.button }} variant="contained" onClick={handleGeneralValidateClosePopup}>
                                                                    Ok
                                                                </Button>
                                                            </Box>
                                                        </Modal>
                                                    </Box>
                                                    <Box>
                                                        <Modal
                                                            open={isExitPopup}
                                                            aria-labelledby="title"
                                                            aria-describedby="description"
                                                            width="50%"
                                                        >
                                                            <Box className={classes.exitPopupStyle}>
                                                                <Typography id="title" variant="h6" component="h2">
                                                                    Warning
                                                                </Typography>
                                                                <Typography id="description" sx={{ mt: 2 }}>
                                                                    {exitPopupMessage}
                                                                </Typography>
                                                                <Grid container spacing={2} sx={{ marginTop: "20px", justifyContent: "end" }}>
                                                                    <Grid item>
                                                                        <Button size="md" type="button"  classes={{ root: classes.button }} variant="contained" onClick={closeExitPopup}>
                                                                            Ok
                                                                        </Button></Grid>
                                                                    <Grid item >
                                                                        <Button size="md" type="button"  classes={{ root: classes.button }} variant="contained" onClick={handleCloseExitPopup}>
                                                                            Cancel
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Modal>
                                                    </Box>
                                                </div>
                                            )}
                                        </Formik>
                                    </Box>
                                    {/* <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            className={classes.summary}
                                        >
                                            <Typography className="w-100">{selectedSubQuestionName}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        
                                        </AccordionDetails>
                                    </Accordion> */}
                                </Grid>
                            </Grid>
                            <Box>
                                <Modal
                                    open={informationPopup}
                                    aria-labelledby="title"
                                    aria-describedby="description"
                                >
                                    <Box className={classes.infoPopup}>
                                        <Typography id="title" variant="h6" component="h2">
                                            Information
                                        </Typography>
                                        <Typography id="description" dangerouslySetInnerHTML={{ __html: informationContent }} sx={{ mt: 2 }} component="p"></Typography>
                                        <Button size="md" sx={{ float: "right" }}  classes={{ root: classes.button }} type="button" variant="contained" onClick={closeModal}>
                                            Accept
                                        </Button>
                                    </Box>
                                </Modal>
                            </Box>
                            <Box>
                                <Modal
                                    open={submitPopUp}
                                    aria-labelledby="title"
                                    aria-describedby="description"
                                    width="50%"
                                >
                                    <Box className={classes.exitPopupStyle}>
                                        <Typography id="title" variant="h6" component="h2">
                                            Warning
                                        </Typography>
                                        <Typography id="description" sx={{ mt: 2 }}>
                                            Are you sure you want to submit the checklist?
                                        </Typography>
                                        <Grid container spacing={2} sx={{ marginTop: "20px", justifyContent: "end" }}>
                                            <Grid item>
                                                <Button size="md"  classes={{ root: classes.button }} type="button" variant="contained" onClick={okSubmitPopup}>
                                                    Ok
                                                </Button> </Grid>
                                            <Grid item>
                                                <Button size="md"  classes={{ root: classes.button }} type="button" variant="contained" onClick={handleCloseSubmitPopup}>
                                                    Cancel
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Modal>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
}