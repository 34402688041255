import React from "react";
import { logindata } from "../pages/login/LoginService";
import axios from "axios";
import { APICore, getAuthorization } from "../apiCore";
import localforage from 'localforage';
import { apiPost } from "../apiCommon";
import { config } from "../config";
import { toast } from "react-toastify";
toast.configure({
  position: toast.POSITION.TOP_LEFT
});
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

const api = new APICore();
function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !(getAuthorization()),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };
const FoodAudit = localforage.createInstance({
  name: "IKEADB",
  storeName: "FoodAudit"
});
// ###########################################################

function loginUser(dispatch, formdata, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);
  // if(formdata){
  //   dispatch({ type: "LOGIN_SUCCESS" });
  //   setError(null);
  //   setIsLoading(false);
  //   history.push("/app/dashboard");
  // }


  logindata(formdata).then((response) => {
    if (response) {
      if (response.data.status === true && response.data.message === "Login Success") {
        localStorage.setItem("Token", response.data.data.authorisation.token)
        localStorage.setItem("userUID", response.data.data.user.userUID)
        localStorage.setItem("roleUID", response.data.data.user.roleUID)
        localStorage.setItem("storeUID", response.data.data.user.storeUID)
        localStorage.setItem("auditEntityUID", response.data.data.user.auditEntityUID)
        localStorage.setItem("usertypeUID", response.data.data.user.usertypeUID)
        localStorage.setItem("firstName", response.data.data.user.firstName)
        localStorage.setItem("roleType", response.data.data.user.roleName)
        localStorage.setItem('internetFlag', 'true');
        localStorage.setItem('newAudit', 'false');
        localStorage.setItem('auditType', '0');
        localStorage.setItem('actionPlan', '');
        localStorage.setItem('strictComments', '');
        localStorage.setItem('closingMeetingComments', '');
        localStorage.setItem('reviewBy', '');
        localStorage.setItem('reviewerValues', []);
        localStorage.setItem('actPlanRowData', []);
        localStorage.setItem("auditStatus", "");
        localStorage.setItem("isCritical", false);
        localStorage.setItem("isBackfromScoring", false);

        if (response.data.data.user.roleUID === '7' || response.data.data.user.roleUID === '12') {
          localStorage.setItem('disableCheckBox', 'true');
        } else {
          localStorage.setItem('disableCheckBox', 'false');
        }
        api.setLoggedInUser(response.data.data.user, response.data.data.authorisation)
        axios.defaults.headers["Authorization"] = `Bearer ${response.data.data.authorisation.token}`;
        // console.log(response)
        dispatch({ type: "LOGIN_SUCCESS" });
        setError(null);
        setIsLoading(false);
        history.push("/app/dashboard");
      } else if (response.data.message === "Password Mismatch") {
        toast.error(response.data.message)
        history.push("/");

      }
      else if (response.data.message === "User Not Found" && response.data.code === 404) {
        toast.error(response.data.message)
        history.push("/");

      }
      else {

        dispatch({ type: "LOGIN_FAILURE" });
        toast.error("Please try again later")
        setError(true);
        setIsLoading(false);
        history.push("/");

      }
    }
    else {
      toast.error("Something went wrong. Please try again later");
      history.push("/");
    }
  }
  )


  //   logindata(formdata).then((res)=>{
  //     if(res){
  //       if(res?.status===true){
  //         localStorage.setItem("Token",res.data.authorisation.token)
  //         console.log(res.data)
  //       // setTimeout(() => {
  //         // localStorage.setItem("id_token", "1");
  //         dispatch({ type: "LOGIN_SUCCESS" });
  //         setError(null);
  //         setIsLoading(false);
  //         history.push("/app/dashboard");
  //       // }, 2000);
  //     } else {
  //       dispatch({ type: "LOGIN_FAILURE" });
  //       setError(true);
  //       setIsLoading(false);
  //     }
  //     }

  // })
}

async function signOut(dispatch, history) {
  localStorage.removeItem("Token");
  localStorage.removeItem("userUID");
  localStorage.removeItem("auditUID");
  localStorage.removeItem("roleUID");
  localStorage.removeItem('storeUID');
  localStorage.removeItem('auditEntityUID');
  localStorage.removeItem("firstName");
  localStorage.removeItem("usertypeUID");
  localStorage.removeItem('internetFlag');
  localStorage.removeItem('newAudit');
  localStorage.removeItem('auditType');
  localStorage.removeItem('disableCheckBox');
  localStorage.removeItem('roleType');
  localStorage.removeItem('transactionUID');
  localStorage.removeItem('closingMeetingAttendees');
  await FoodAudit.removeItem("answerresponseDetails");
  await FoodAudit.removeItem("questionDetails");
  await FoodAudit.removeItem("storedAnswers");
  await FoodAudit.removeItem("reviewerValues");
  await FoodAudit.removeItem("actPlanRowData");
  await FoodAudit.removeItem("storeData");
  await FoodAudit.removeItem("rowValue");
  localStorage.removeItem('isCritical');
  localStorage.removeItem('isBackfromScoring');
  localStorage.removeItem('actionPlan');
  localStorage.removeItem('strictComments');
  localStorage.removeItem('closingMeetingComments');
  localStorage.removeItem('reviewBy');
  localStorage.removeItem('auditStatus');
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}