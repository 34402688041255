
// import jwtDecode from 'jwt-decode';
import axios from 'axios';
//axios.defaults.headers.post['Content-Type'] = 'application/json';

// axios interceptor

//@param {*} token

axios.interceptors.response.use(
    (response) => {

        return response;
    },
    (error) => {

       // console.log("apiError", error);

        return Promise.reject({ errorAPI: true, errorMessage: error.message });

    }
);

const setAuthorization = (token) => {
    if (token) axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    else delete axios.defaults.headers["Authorization"];
};

const getAuthorization = () => {
    let user = getUserFromCookie();
    if (user) {
        const token = user.token;
        if (token) {
            return token.token;
        }
    }

}

const getUserFromCookie = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const AUTH_SESSION_KEY = 'bixware_user';

class APICore {

     setLoggedInUser = (user, authorisation) => {
        if (user) {
            let usertoken = {
                user: user,
                token: authorisation
            }
            sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(usertoken));
        }
        else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    create = async (url, data) => {
        const options = {
            method: 'POST',        
            headers: { 'content-type': 'application/json' },          
            data: data,          
            url: url
        };
        return axios(options);
    };

    createForm = async (url, data) => {
        const options = {
            method: 'POST',          
            headers: { 'content-type': 'multipart/form-data' },          
            data: data,          
            url: url
        };
        return axios(options);
    };
}

 let user = getUserFromCookie();
if (user) {
    const token = user.token;
    if (token) {
        setAuthorization(token.token);
    }
 }


export { APICore,setAuthorization, getAuthorization };