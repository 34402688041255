import defaultTheme from "./default";

import { createTheme } from '@material-ui/core/styles';

const overrides = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Noto IKEA, Noto Sans, Roboto, Open Sans, system-ui, sans-serif !important"
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
  @font-face {
    font-display: swap;
    font-family: Noto IKEA;
    font-style: italic;
    font-weight: 400;
    src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400i.latin-ext.5f8f91ea.woff2) format("woff2");
    unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf, u+2113, u+2c60-2c7f, u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Noto IKEA;
    font-style: italic;
    font-weight: 400;
    src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400i.latin.10395910.woff2) format("woff2");
    unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd
}

@font-face {
    font-display: swap;
    font-family: Noto IKEA;
    font-style: italic;
    font-weight: 700;
    src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700i.latin-ext.3637155a.woff2) format("woff2");
    unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf, u+2113, u+2c60-2c7f, u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Noto IKEA;
    font-style: italic;
    font-weight: 700;
    src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700i.latin.29eb18bb.woff2) format("woff2");
    unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd
}

@font-face {
    font-display: swap;
    font-family: Noto IKEA;
    font-style: normal;
    font-weight: 400;
    src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400.latin-ext.edd421f4.woff2) format("woff2");
    unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf, u+2113, u+2c60-2c7f, u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Noto IKEA;
    font-style: normal;
    font-weight: 400;
    src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400.latin.647e877d.woff2) format("woff2");
    unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd
}

@font-face {
    font-display: swap;
    font-family: Noto IKEA;
    font-style: normal;
    font-weight: 700;
    src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700.latin-ext.97f0d455.woff2) format("woff2");
    unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf, u+2113, u+2c60-2c7f, u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Noto IKEA;
    font-style: normal;
    font-weight: 700;
    src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700.latin.d7277c39.woff2) format("woff2");
    unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd
}
      `,
    },
  },
};

export default {
  default: createTheme({ ...defaultTheme, ...overrides }),
};
