import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  button:{
    backgroundColor:"#0257a7 !important"
  },
  titleBold: {
    fontWeight: 600,
  },
  summary: {
    backgroundColor: 'gray',
  },
  gridSpace: {
    marginLeft: '8px',
    marginRight: '8px',
    [theme.breakpoints.down("xs")]: {
      marginLeft: '0px',
      marginRight: '0px',
    },
  },
  gridChecklistSpace: {
    marginLeft: '8px',
    marginRight: '8px',
  },
  radioButton: {
    border: "1px solid grey",
    borderRadius: "5px",
    padding: "1em",
    width: "6em",
    height: "32px",
    WebkitAppearance: "none",
    cursor: "pointer",
    "&:checked": {
      backgroundColor: "#0257a7",
      border: "none"
    },
    "&:disabled": {
      backgroundColor: "#e2d6d6",
      border: "none"
    },
    [theme.breakpoints.down("xs")]: {
      width: "46px",
      height: "12px",
    },
  },
  radioDisabledButton: {
    border: "1px solid grey",
    borderRadius: "5px",
    padding: "1em",
    width: "6em",
    height: "32px",
    WebkitAppearance: "none",
    cursor: "pointer",
    "&:checked": {
      backgroundColor: "#0257a7",
      border: "none"
    },
    [theme.breakpoints.down("xs")]: {
      width: "46px",
      height: "12px",
    },
  },
  radioChecklistButton: {
    border: "1px solid grey",
    borderRadius: "5px",
    padding: "1em",
    width: "8em",
    height: "32px",
    WebkitAppearance: "none",
    cursor: "pointer",
    "&:checked": {
      backgroundColor: "#0257a7",
      border: "none"
    },
    // "&:disabled": {
    //   backgroundColor: "#e2d6d6",
    //   border: "none"
    // },
    [theme.breakpoints.down("xs")]: {
      width: "79px",
      height: "12px",
    },
  },

  radioLabel: {
    top: '-8px !important',
    left: '20px !important',
    [theme.breakpoints.down("xs")]: {
      top: '-8px !important',
      left: '4px !important',
    },
  },
  radioLabelChecked:{
    color:"white !important",
    top: '-8px !important',
    left: '20px !important',
    [theme.breakpoints.down("xs")]: {
      top: '-8px !important',
      left: '4px !important',
    },
  },

  infoPopup: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85vw",
    height: "70vh",
    overflow: "auto",
    backgroundColor: 'white',
    border: '2px solid grey',
    boxShadow: 24,
    p: 4,
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      height: "70vh",
    },
  },
  container: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  subTypeContainer: {
    // padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      padding: 0
    },
  },
  DropzoneArea: {
    padding: "40px"
  },
  DropzoneAreaDisabled: {
    padding: "40px",
    pointerEvents: "none"
  }
  ,
  popupStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60vw",
    overflow: "auto",
    backgroundColor: "#fff",
    border: '2px solid #000',
    boxShadow: 24,
    padding: '2%',

  },
  smallPopupStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40vw",
    overflow: "auto",
    backgroundColor: "#fff",
    border: '2px solid #000',
    boxShadow: 24,
    padding: '2%',
    [theme.breakpoints.down("xs")]: {
      width: "60vw",
    },
  },
  exitPopupStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "45vw",
    overflow: "auto",
    backgroundColor: "#fff",
    border: '2px solid #000',
    boxShadow: 24,
    padding: '2%',
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "45vw",
      overflow: "auto",
      backgroundColor: "#fff",
      border: '2px solid #000',
      boxShadow: 24,
      padding: '2%',
      [theme.breakpoints.down("xs")]: {
        width: "60vw",
      },
    },
    [`${theme.breakpoints.down('md')} and (orientation: portrait)`]: {
      width: "60vw",
    }
  },

}));