import {baseURL} from "../../base";
import axios from "axios";

export async function logindata(data) {
    try {
      return await axios
        .post(`${baseURL}/login`, data)
        .then((response) => {
          return response;
        })
        .catch((error) => {
        //   catcherror(error);
        });
    } catch (error) {
    //   catcherror(error);
    }
  }