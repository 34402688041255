import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    maxWidth: "100vw",
    overflow: "auto",
    //display: 'flex',
    height: "100vh",
    paddingLeft: "30px",
    paddingRight: "30px",
    // paddingBottom: "3%",
    [`${theme.breakpoints.only('xs')} and (orientation: portrait)`]: {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
    // [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
    //   paddingBottom: "5%",
    // },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
}));
