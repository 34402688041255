import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  InputAdornment,
  TextField,
  Fade,
  Card,
  CardMedia,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
//import logo from "./logo.svg";
import logo from "../../logo.png";
import google from "../../images/google.svg";
import MailLockTwoToneIcon from '@mui/icons-material/MailLockTwoTone';
import EnhancedEncryptionTwoToneIcon from '@mui/icons-material/EnhancedEncryptionTwoTone';
import LoginTwoToneIcon from '@mui/icons-material/LoginTwoTone';
import { Paper, Avatar, Link, Box } from '@material-ui/core'
import ikea from '../../images/ikea.jpg'
import Footer from "../../components/Footer/Footer";
// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [formdata, setFormdata] = useState({
    userName: "",
    password: ""
  })

  //   useEffect(() => {
  //     document
  //         .getElementById("Your-element-id")
  //         .addEventListener("keydown", function (event) {
  //             if (event.code === "Enter" || event.code === "NumpadEnter") {
  //                 event.preventDefault();
  //                 document.getElementById("submit-element").click();
  //             }
  //         });
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormdata((prev) => {
      return { ...prev, [name]: value }
    })

  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} xl={5} lg={5} className={classes.loginresponsive}
          style={{ justifyContent: "end", display: "flex", alignItems: "center", paddingLeft: "8%", paddingRight: "8%" }}
        >

          {/* 
          <div className={classes.logotypeContainer}>
            <div className={classes.formContainer}>
              <div className={classes.form}> */}
          <React.Fragment>
            <Grid container>
              <Grid item xs={12} style={{ justifyContent: "start", display: "flex", }}>
                <img src={logo} alt="logo" className={classes.logotypeImage} />
              </Grid>
              <Grid item xs={12} style={{ justifyContent: "start", display: "flex", }}>
                <Typography variant="h2" className={classes.logintypo}>
                  LOGIN
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container >
                  <Grid item xs={12} style={{ padding: "0px" }}>
                    <TextField
                      id="email"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      // defaultValue="food_auditor"
                      name="userName"
                      onChange={handleChange}
                      margin="normal"
                      placeholder="Email Address"
                      type="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0px" }}>
                    <TextField
                      // defaultValue="Audit@2023"
                      id="password"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                        onKeyPress: (e) => {
                          if (e.key === "Enter") {
                            document.getElementById("submitBtn").click();
                          }
                        }
                      }}
                      name="password"
                      onChange={handleChange}
                      margin="normal"
                      placeholder="Password"
                      type="password"
                      fullWidth
                    /></Grid>
                  <Grid item xs={12} style={{ justifyContent: "center", margin: "10px", display: "flex" }}>
                    {/* <div className={classes.formButtons}> */}
                    {isLoading ? (
                      <CircularProgress size={26} className={classes.loginLoader} />
                    ) : (
                      <Button
                        startIcon={<LoginTwoToneIcon />}
                        disabled={
                          formdata.userName.length === 0 || formdata.password.length === 0
                        }
                        id="submitBtn"
                        onClick={() =>
                          loginUser(
                            userDispatch,
                            formdata,
                            props.history,
                            setIsLoading,
                            setError,
                          )
                        }
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Login
                      </Button>
                    )}
                    {/* <Button
                      color="primary"
                      size="large"
                      className={classes.forgetButton}
                    >
                      Forget Password
                    </Button> */}

                    {/* </div> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
          {/* </div>
            </div>
          </div> */}
        </Grid>
        <Grid item lg={7} xl={7} md={6} style={{ justifyContent: "start", display: "flex" }} className={classes.responsive}>
          <div className={classes.loginbanner}
            style={{ height: "100vh", width: "100vw", boxShadow: "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>

          </div>
          {/* <Card
            // className={classes.loginpageblueboxres}

            raised
            style={{
              width: "75vw%",
              height: "75vh",
              // maxWidth: 280,
              borderRadius: "10px",
              top: "7%",
              position: "relative",
              boxShadow: "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)"

            }}
          >
            <div style={{ position: "relative" }}>
              <CardMedia className={classes.loginpageblueboxres}
                component="img"
                height="100%"
                image={ikea}
                alt={"alt"}
              // title={"titleasdasdsada"}
              // sx={{ padding: "1em 1em 0 1em", objectFit: "contain", }}
              />
            </div>
          </Card> */}
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </>
  );
}

export default withRouter(Login);
