import React, { useState } from "react";
import Button from "@mui/material/Button";

export default function AuditForm (props) {

return(

    <div>
        <h1>AuditForm</h1>
<Button onClick={()=>{props.history.push("/app/ui/questionnaire");}}>Question</Button>

    </div>
);

}