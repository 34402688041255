import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { Box, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from '@mui/material/Grid';
import * as Yup from "yup";
import { useReactToPrint } from "react-to-print";
import PageTitle from '../../components/PageTitle/PageTitle';
import useStyles from "./style";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useHistory } from "react-router-dom";
import { apiPost, apiFormDataPost } from '../../apiCommon';
import { config } from '../../config';
import { imageURL } from "../../base";
import localforage from 'localforage';
import { toast } from "react-toastify";
import { ThreeDots } from 'react-loader-spinner';
import Modal from '@mui/material/Modal';
import Chart from "react-apexcharts";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { format } from 'date-fns';
import 'react-dropzone-uploader/dist/styles.css'
import { DropzoneArea } from 'material-ui-dropzone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@mui/material/Tooltip';
import printJS from 'print-js-plus';
import '../../style.css';
import {
    LineChart,
    Line,
    AreaChart,
    Area,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    Tooltip as Tool,
    ReferenceArea,
    ResponsiveContainer
} from "recharts";
// import { ClientSideRowModelModule } from "ag-grid-community/client-side-row-model";
// import { ModuleRegistry } from "ag-grid-community/core";
// ModuleRegistry.registerModules([ClientSideRowModelModule]);
toast.configure({
    position: toast.POSITION.TOP_LEFT
});

const FoodAudit = localforage.createInstance({
    name: "IKEADB",
    storeName: "FoodAudit"
});


const okValueGetter = (params) => {
    return params.data.Ok ? ('Quantity - ' + params.data.Ok + ' Points - ' + params.data.Ok) : ('Quantity - ' + 0 + ' Points - ' + 0);
};

const notOkValueGetter = (params) => {
    return params.data.NotOK ? ('Quantity - ' + params.data.NotOK + ' Points - ' + 0) : ('Quantity - ' + 0 + ' Points - ' + 0);
};

const goodValueGetter = (params) => {
    return params.data.Good ? ('Quantity - ' + params.data.Good + ' Points - ' + (params.data.Good * 2)) : ('Quantity - ' + 0 + ' Points - ' + 0);
};

const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector("#myGrid");
    eGridDiv.style.width = "";
    eGridDiv.style.height = "";
    api.setGridOption("domLayout", "print");
};

const setNormal = (api) => {
    const eGridDiv = document.querySelector("#myGrid");
    eGridDiv.style.width = "700px";
    eGridDiv.style.height = "200px";
    api.setGridOption("domLayout", undefined);
};

export default function ScoringTable(props) {

    var classes = useStyles();
    let history = useHistory();
    const [gridApi, setgridApi] = useState(null);
    const [actPlanGridApi, setActPlanGridApi] = useState(null);
    const [rowData, setrowData] = useState(null);
    const [actPlanReviewerRowData, setActPlanReviewerRowData] = useState(null);
    const [actPlanRowData, setActPlanRowData] = useState([]);
    const [finalData, setFinalData] = useState(null);
    const [rvalue, setRValue] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const [columnDef, setcolumnDef] = useState([]);
    const [actPlanReviewerColumnDef, setActPlanReviewerColumnDef] = useState([]);
    const [actPlanStoreColumnDef, setActPlanStoreColumnDef] = useState([]);
    const [role, setRole] = useState(JSON.parse(localStorage.getItem('roleUID')));
    const [loading, setLoading] = useState(false);
    const [isPropertyPassOrFail, setIsPropertyPassOrFail] = useState(false);
    const [passOrFail, setPassOrFail] = useState("");
    const [isSecurityPassOrFail, setIsSecurityPassOrFail] = useState("");
    const [securityTotal, setSecurityTotal] = useState("");
    const [isExitPopup, setIsExitPopup] = React.useState(false);
    const [isCritical, setIsCritical] = React.useState(false);
    const [isReviewerActionPopup, setIsReviewerActionPopup] = React.useState(false);
    const [isStoreActionPopup, setIsStoreActionPopup] = React.useState(false);
    const [isCompletePopup, setIsCompletePopup] = React.useState(false);
    const [isReviewerViewPopup, setIsReviewerViewPopup] = React.useState(false);
    const [isStoreViewPopup, setIsStoreViewPopup] = React.useState(false);
    const [isReopenPopup, setIsReopenPopup] = React.useState(false);
    const [isStoreUploadPopup, setIsStoreUploadPopup] = React.useState(false);
    const [localComments, setLocalComments] = useState("");
    const [closingComments, setClosingComments] = useState("");
    const [actionPlanComments, setActionPlanComments] = useState("");
    const [reviewer, setReviewer] = useState("");
    const [reviewerAction, setReviewerAction] = useState({});
    const [uploadFiles, setUploadFiles] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [chartValue, setChartValue] = useState(1);
    const [dueDate, setDueDate] = React.useState(format(new Date(), "yyyy-MM-dd hh:mm a"));
    const [reviewerMessageToAuditor, setReviewerMessageToAuditor] = useState("");
    // const [initialLocalComments, setInitialLocalComments] = useState()
    const handleCloseExitPopup = () => setIsExitPopup(false);
    const handleCloseReviewerActionPopup = () => {
        setIsReviewerActionPopup(false);
        setIsUpdate(false)
    };
    const handleCloseStoreActionPopup = () => {
        setIsStoreActionPopup(false);
        setIsUpdate(false)
    };
    const handleCloseCompletePopup = () => setIsCompletePopup(false);
    const handleCloseReopenPopup = () => setIsReopenPopup(false);
    const handleCloseStoreUploadPopup = () => setIsStoreUploadPopup(false);
    const handleCloseReviewerViewPopup = () => setIsReviewerViewPopup(false);
    const handleCloseStoreViewPopup = () => setIsStoreViewPopup(false);
    const [responseStatus, setResponseStatus] = useState("");
    const gridRef = useRef(null);
    const [updateReviewerActionInitialValues, setUpdateReviewerActionInitialValues] = useState({});
    const [updateStoreActionInitialValues, setUpdateStoreActionInitialValues] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);

    const auditStatus = localStorage.getItem("auditStatus");

    const initialValues = {
        reviewer: localStorage.getItem('reviewBy') == 'null' || localStorage.getItem('reviewBy') == null ? '' : localStorage.getItem('reviewBy'),
        actionPlanComments: localStorage.getItem('actionPlan') == 'null' || localStorage.getItem('actionPlan') == null ? '' : localStorage.getItem('actionPlan'),
        localComments: localStorage.getItem('strictComments') == 'null' || localStorage.getItem('strictComments') == null ? '' : localStorage.getItem('strictComments'),
        closingMeetingAttendees: localStorage.getItem("closingMeetingComments") == 'null' || localStorage.getItem("closingMeetingComments") == null ? '' : localStorage.getItem("closingMeetingComments")
    }

    const reviewerActionInitialValues = {
        actionplanComments: "",
        uploadimage: [],
        uploadvideo: []
    }

    const StoreActionInitialValues = {
        assignTo: "",
        dueDate: "",
        storecomments: "",
    }
    useEffect(() => {
        (async () => {
            setReviewer(localStorage.getItem('reviewBy') == 'null' || localStorage.getItem('reviewBy') == null ? '' : localStorage.getItem('reviewBy'))
            setClosingComments(localStorage.getItem('closingMeetingComments') == 'null' || localStorage.getItem('closingMeetingComments') == null ? '' : localStorage.getItem('closingMeetingComments'))
            setActionPlanComments(localStorage.getItem('actionPlan') == 'null' || localStorage.getItem('actionPlan') == null ? '' : localStorage.getItem('actionPlan'))
            setLocalComments(localStorage.getItem('strictComments') == 'null' || localStorage.getItem('strictComments') == null ? '' : localStorage.getItem('strictComments'))
            setRValue(await FoodAudit.getItem("reviewerValues"))
            // setActPlanRowData(await FoodAudit.getItem("actPlanRowData"))
            setQuestionData(await FoodAudit.getItem("questionDetails"))
            if (localStorage.getItem("roleType") === "Reviewer") {
                if (localStorage.getItem("auditStatus") === "Audit Completed") {
                    setActPlanReviewerColumnDef([
                        {
                            headerName: 'No',
                            colId: 'referenceNo',
                            field: 'referenceNo'
                        },
                        {
                            headerName: 'Question',
                            colId: 'questionName',
                            width: 250, field: 'questionName'
                        },
                        {
                            headerName: 'Valuation',
                            colId: 'valuation',
                            field: 'valuation'
                        },
                        {
                            headerName: 'Deviation / Comments', width: 300,
                            colId: 'actionplanComments',
                            field: 'actionplanComments',
                            onCellClicked: (params) => {
                                if (localStorage.getItem('internetFlag') == 'true') {
                                    if (localStorage.getItem("auditStatus") === "Audit Completed") {
                                        return null
                                    } else {
                                        // console.log(params);
                                        setReviewerAction(params.data)
                                        setIsUpdate(true)
                                        setIsReviewerActionPopup(true)
                                    }
                                } else {
                                    toast.error("You are not online to proceed");
                                }


                            }
                        },
                        {
                            headerName: 'Actions',
                            colId: 'questionnaireUID',
                            field: 'questionnaireUID',
                            cellRenderer: (params) => (
                                <>
                                    <Tooltip title="View">
                                        <IconButton
                                            onClick={(e) => {
                                                if (localStorage.getItem('internetFlag') == 'true') {
                                                    setReviewerAction(params.data);
                                                    setIsReviewerViewPopup(true);
                                                } else {
                                                    toast.error("You are not online to proceed");
                                                }

                                            }}><VisibilityIcon /></IconButton>
                                    </Tooltip>
                                </>
                            )
                        },
                    ])
                } else {
                    setActPlanReviewerColumnDef([
                        {
                            headerName: 'No',
                            colId: 'referenceNo',
                            field: 'referenceNo'
                        },
                        {
                            headerName: 'Question',
                            colId: 'questionName',
                            width: 350, field: 'questionName'
                        },
                        {
                            headerName: 'Valuation',
                            colId: 'valuation',
                            field: 'valuation'
                        },
                        {
                            headerName: 'Deviation / Comments', width: 350,
                            colId: 'actionplanComments',
                            field: 'actionplanComments',
                            onCellClicked: (params) => {
                                if (localStorage.getItem('internetFlag') == 'true') {
                                    if (localStorage.getItem("auditStatus") === "Audit Completed") {
                                        return null
                                    } else {
                                        // console.log(params);
                                        setReviewerAction(params.data)
                                        setIsUpdate(true)
                                        setIsReviewerActionPopup(true)
                                    }
                                } else {
                                    toast.error("You are not online to proceed");
                                }


                            }
                        }
                    ])
                }

            }

            if (localStorage.getItem("roleType") === "Store User") {
                setActPlanStoreColumnDef([
                    {
                        headerName: 'No',
                        colId: 'referenceNo',
                        width: 100, field: 'referenceNo',
                    },
                    {
                        headerName: 'Question',
                        colId: 'questionName',
                        width: 150, field: 'questionName',
                    },
                    {
                        headerName: 'Valuation',
                        colId: 'valuation',
                        width: 100, field: 'valuation',
                    },
                    {
                        headerName: 'Deviation / Comments',
                        colId: 'actionplanComments',
                        width: 150, field: 'actionplanComments',
                    },
                    {
                        headerName: 'Who',
                        colId: 'assignTo',
                        width: 100, field: 'assignTo',

                        onCellClicked: (params) => {
                            if (localStorage.getItem('internetFlag') == 'true') {
                                if (localStorage.getItem("auditStatus") === "Audit Completed") {
                                    return null
                                } else {
                                    // console.log(params);
                                    setReviewerAction(params.data);
                                    setIsUpdate(true)
                                    if (params.data.dueDate !== null) {
                                        setDueDate(new Date(params.data.dueDate))
                                    } else {
                                        setDueDate(new Date())
                                    }
                                    setIsStoreActionPopup(true);
                                }
                            } else {
                                toast.error("You are not online to proceed");
                            }


                        }
                    },
                    {
                        headerName: 'When',
                        colId: 'dueDate',
                        width: 100, field: 'dueDate',
                        onCellClicked: (params) => {
                            if (localStorage.getItem('internetFlag') == 'true') {
                                if (localStorage.getItem("auditStatus") === "Audit Completed") {
                                    return null
                                } else {
                                    // console.log(params);
                                    setReviewerAction(params.data);
                                    setIsStoreActionPopup(true);
                                    if (params.data.dueDate !== null) {
                                        setDueDate(new Date(params.data.dueDate))
                                    } else {
                                        setDueDate(new Date())
                                    }
                                    setIsUpdate(true)
                                }
                            } else {
                                toast.error("You are not online to proceed");
                            }


                        }
                    },
                    {
                        headerName: 'What',
                        colId: 'storecomments',
                        width: 100, field: 'storecomments',
                        onCellClicked: (params) => {
                            if (localStorage.getItem('internetFlag') == 'true') {
                                if (localStorage.getItem("auditStatus") === "Audit Completed") {
                                    return null
                                } else {
                                    console.log(params);
                                    setReviewerAction(params.data);
                                    setIsStoreActionPopup(true);
                                    if (params.data.dueDate !== null) {
                                        setDueDate(new Date(params.data.dueDate))
                                    }
                                    else {
                                        setDueDate(new Date())
                                    }
                                    setIsUpdate(true)
                                }
                            } else {
                                toast.error("You are not online to proceed");
                            }


                        }
                    },
                    {
                        headerName: 'Actions',
                        colId: 'questionnaireUID',
                        field: 'questionnaireUID',
                        width: 200,
                        cellRenderer: (params) => (
                            <>
                                <Tooltip title="Upload Files">
                                    <span>
                                        <IconButton disabled={localStorage.getItem("auditStatus") === "Audit Completed" ? true : false}
                                            onClick={(e) => {
                                                if (localStorage.getItem('internetFlag') == 'true') {
                                                    setReviewerAction(params.data);
                                                    setIsStoreUploadPopup(true);
                                                } else {
                                                    toast.error("You are not online to proceed");
                                                }

                                            }}><FileUploadIcon /></IconButton>
                                    </span>
                                </Tooltip>

                                <Tooltip title="Complete Question">
                                    <span>
                                        <IconButton disabled={localStorage.getItem("auditStatus") === "Audit Completed" ? true : false}
                                            onClick={(e) => {
                                                if (localStorage.getItem('internetFlag') == 'true') {

                                                    if (params.data.actionplanStatus === "Action Plan Completed") {
                                                        toast.error("The question has already been marked as complete, so you are unable to click complete again.");
                                                    }
                                                    else {
                                                        setSelectedRow(params);
                                                        setReviewerAction(params.data);
                                                        setIsCompletePopup(true);
                                                    }
                                                } else {
                                                    toast.error("You are not online to proceed");
                                                }
                                            }}><CheckCircleOutlineIcon /></IconButton>
                                    </span>
                                </Tooltip>

                                <Tooltip title="Reopen Question">
                                    <span>
                                        <IconButton disabled={localStorage.getItem("auditStatus") === "Audit Completed" ? true : false}
                                            onClick={(e) => {
                                                if (localStorage.getItem('internetFlag') == 'true') {
                                                    if (params.data.actionplanStatus === "Action Plan Inprogress") {
                                                        toast.error("The question has already been marked as reopen, so you are unable to click reopen again.");
                                                    }
                                                    else {
                                                        setSelectedRow(params);
                                                        setReviewerAction(params.data);
                                                        setIsReopenPopup(true);
                                                    }
                                                } else {
                                                    toast.error("You are not online to proceed");
                                                }
                                            }}><AutorenewIcon /></IconButton>
                                    </span>
                                </Tooltip>
                                {(localStorage.getItem("auditStatus") === "Audit Completed") ? (<>

                                    <Tooltip title="View">
                                        <IconButton
                                            onClick={(e) => {
                                                if (localStorage.getItem('internetFlag') == 'true') {
                                                    setReviewerAction(params.data);
                                                    setUploadFiles(params.data.uploadedFiles);
                                                    setIsStoreViewPopup(true)
                                                } else {
                                                    toast.error("You are not online to proceed");
                                                }

                                            }}><VisibilityIcon /></IconButton>
                                    </Tooltip>
                                </>) : null}
                            </>
                        )
                    },
                    {
                        headerName: 'Status',
                        colId: 'actionplanStatus',
                        width: 200, field: 'actionplanStatus'
                    },
                ])
            }
            if (localStorage.getItem('auditType') == '2') {
                setcolumnDef([

                    {
                        headerName: 'Sections',
                        colId: 'sections',
                        width: 250, field: 'sections'
                    },
                    {
                        headerName: 'Total', width: 50,
                        colId: 'total',
                        field: 'total'
                    },
                    {
                        headerName: 'OK', width: 200,
                        colId: 'value1',
                        valueGetter: okValueGetter,
                    },
                    {
                        headerName: 'Not OK', width: 200,
                        colId: 'value2',
                        valueGetter: notOkValueGetter,
                    },
                    {
                        headerName: 'Good', width: 200,
                        colId: 'value3',
                        valueGetter: goodValueGetter,
                    },
                    {
                        headerName: 'Percentage',
                        colId: 'percentage',
                        width: 100, field: 'percentage'
                    },
                ]);
            }
            else
                if (localStorage.getItem('auditType') == '3') {
                    setcolumnDef([

                        {
                            headerName: 'Sections',
                            colId: 'sections',
                            field: 'sections'
                        },
                        {
                            headerName: 'OK',
                            colId: 'value1',
                            field: 'Ok'
                        },
                        {
                            headerName: 'Not OK',
                            colId: 'value2',
                            field: 'NotOk'
                        },
                        {
                            headerName: 'N/A',
                            colId: 'value3',
                            field: 'NotApplicable'
                        },
                    ]);
                }
                else
                    if (localStorage.getItem('auditType') == '4') {
                        setcolumnDef([

                            {
                                headerName: 'Sections',
                                colId: 'sections',
                                width: 300, field: 'sections'
                            },
                            {
                                headerName: 'OK',
                                colId: 'value1',
                                field: 'Ok'
                            },
                            {
                                headerName: 'Not OK',
                                colId: 'value2',
                                field: 'NotOk'
                            },
                            {
                                headerName: 'N/A',
                                colId: 'value3',
                                field: 'NotApplicable'
                            },
                            {
                                headerName: 'Priority',
                                colId: 'priority',
                                field: 'priority'
                            },
                            {
                                headerName: 'Risk Rating',
                                colId: 'riskRating',
                                field: 'riskRating'
                            },
                            {
                                headerName: 'Score',
                                colId: 'scoring',
                                field: 'scoring'
                            },
                        ]);
                    }
                    else {
                        setcolumnDef([
                            {
                                headerName: 'Sections',
                                colId: 'sections',
                                width: 300, field: 'sections'
                            },
                            {
                                headerName: 'I',
                                colId: 'value1',
                                field: 'value1'
                            },
                            {
                                headerName: 'II',
                                colId: 'value2',
                                field: 'value2'
                            },
                            {
                                headerName: 'III',
                                colId: 'value3',
                                field: 'value3'
                            },
                            {
                                headerName: 'IV',
                                colId: 'value4',
                                field: 'value4'
                            },
                            {
                                headerName: 'Score',
                                colId: 'score',
                                field: 'score'
                            },
                            {
                                headerName: 'Total',
                                colId: 'total',
                                field: 'total'
                            }
                        ]);
                    }
            // console.log(await FoodAudit.getItem("reviewerValues"))

            const questionsList = await FoodAudit.getItem("questionDetails");
            // console.log(questionsList);
            let finalAns = [];
            let scoringDetails = [];
            let totalQuestions = 0;
            let totalCount = 0;
            questionsList.forEach((q1, i1) => {
                let value1Count = 0;
                let value2Count = 0;
                let value3Count = 0;
                let value4Count = 0;
                let foodValue1Count = 0;
                let foodValue2Count = 0;
                let foodValue3Count = 0;
                let foodValue4Count = 0;
                let questionsCount = 0;
                let priority = 0;
                let riskRating = 0;
                let scoringForSecurity = 0;
                q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                    q2.auditSubtypeUIDDetails.forEach((q3, i3) => {
                        let userUID = localStorage.getItem("userUID")
                        if (localStorage.getItem('auditType') == '2') {
                            if (q3.valuation !== '4') {
                                totalCount++;
                            }
                        }
                        if (q3.valuation !== "") {

                            if (localStorage.getItem('auditType') == '2') {
                                if (q3.valuation === "1")
                                    value1Count++;
                                else if (q3.valuation === "2")
                                    value2Count++;
                                else if (q3.valuation === "3")
                                    value3Count++;
                                else if (q3.valuation === "4")
                                    value4Count++;
                                if (q3.valuation !== "4")
                                    questionsCount++;
                                finalAns.push({
                                    "userUID": userUID,
                                    "valuation": q3.valuation,
                                    "comments": q3.comments,
                                    "auditSubtypeUID": q3.auditSubtypeUID,
                                    "observationUID": q3.observationUID,
                                    "questionnaireUID": q3.questionnaireUID,
                                    "transactionUID": q3.transactionUID,
                                    "auditSubtypeUID": q3.auditSubtypeUID,
                                    "images": q3.images,
                                    "videos": q3.videos,

                                })
                            } else
                                if (localStorage.getItem('auditType') == '3') {
                                    if (q3.valuation === "1")
                                        value1Count++;
                                    else if (q3.valuation === "2")
                                        value2Count++;
                                    else if (q3.valuation === "3")
                                        value3Count++;

                                    questionsCount++;
                                    finalAns.push({
                                        "userUID": userUID,
                                        "valuation": q3.valuation,
                                        "comments": q3.comments,
                                        "observationUID": q3.observationUID,
                                        "questionnaireUID": q3.questionnaireUID,
                                        "transactionUID": q3.transactionUID,
                                        "images": q3.images,
                                        "videos": q3.videos,

                                    })
                                }
                                else
                                    if (localStorage.getItem('auditType') == '4') {
                                        let tempPriority = parseInt(q3.priority);
                                        priority += tempPriority;
                                        riskRating += tempPriority;
                                        if (q3.valuation === "1") {
                                            value1Count++;
                                            scoringForSecurity += tempPriority;
                                        }
                                        else if (q3.valuation === "2") {
                                            value2Count++;
                                        }
                                        else if (q3.valuation === "3") {
                                            value3Count++;
                                            riskRating -= tempPriority;
                                        }

                                        questionsCount++;
                                        finalAns.push({
                                            "userUID": userUID,
                                            "valuation": q3.valuation,
                                            "comments": q3.comments,
                                            "observationUID": q3.observationUID,
                                            "questionnaireUID": q3.questionnaireUID,
                                            "transactionUID": q3.transactionUID,
                                            "images": q3.images,
                                            "videos": q3.videos,

                                        })
                                    } else {
                                        if (q3.valuation === "1") {
                                            value1Count++;
                                            foodValue1Count = parseInt(q3.priority) + foodValue1Count;
                                        }
                                        else if (q3.valuation === "2") {
                                            value2Count++;
                                            foodValue2Count = parseInt(q3.priority) + foodValue2Count;
                                        }
                                        else if (q3.valuation === "3") {
                                            value3Count++;
                                            foodValue3Count = parseInt(q3.priority) + foodValue3Count;
                                        }
                                        else if (q3.valuation === "4") {
                                            value4Count++;
                                            foodValue4Count = parseInt(q3.priority) + foodValue4Count;
                                        }
                                        questionsCount++;
                                        finalAns.push({
                                            "userUID": userUID,
                                            "valuation": q3.valuation,
                                            "comments": q3.comments,
                                            "status": checkStatusStandard(q3.valuation ? q3.valuation : ""),
                                            "observationUID": q3.observationUID,
                                            "questionnaireUID": q3.questionnaireUID,
                                            "transactionUID": q3.transactionUID,
                                            "images": q3.images,
                                            "videos": q3.videos,

                                        })
                                    }

                        }

                    })
                })
                if (localStorage.getItem('auditType') == '2') {
                    let percentage = 0;
                    if (totalCount !== 0) {
                        percentage = (((value1Count) / totalCount) * 100);
                    }
                    if (q1.auditTypeName !== "External elements" && q1.auditTypeName !== "Documents") {
                        if (percentage <= 65) {
                            setIsPropertyPassOrFail(true);
                        }
                    }
                    scoringDetails.push({
                        "sections": q1.auditTypeName,
                        "total": totalCount,
                        "Ok": value1Count,
                        "NotOk": value2Count,
                        "Good": value3Count,
                        "value4": value4Count,
                        "percentage": `${percentage.toFixed()}%`
                    })
                    value1Count = 0;
                    value2Count = 0;
                    value3Count = 0;
                    value4Count = 0;
                    questionsCount = 0;
                    totalCount = 0;
                } else
                    if (localStorage.getItem('auditType') == '3') {
                        scoringDetails.push({
                            "sections": q1.auditTypeName,
                            "Ok": value1Count,
                            "NotOk": value2Count,
                            "NotApplicable": value3Count,
                        })
                        value1Count = 0;
                        value2Count = 0;
                        value3Count = 0;
                        questionsCount = 0;
                    }
                    else
                        if (localStorage.getItem('auditType') == '4') {
                            scoringDetails.push({
                                "sections": q1.auditTypeName,
                                "Ok": value1Count,
                                "NotOk": value2Count,
                                "NotApplicable": value3Count,
                                "priority": priority,
                                "riskRating": riskRating,
                                "scoring": scoringForSecurity
                            })
                            value1Count = 0;
                            value2Count = 0;
                            value3Count = 0;
                            questionsCount = 0;
                            priority = 0;
                            riskRating = 0;
                            scoringForSecurity = 0;
                        }
                        else {
                            let criticalValueCount = 0;
                            q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                                q2.auditSubtypeUIDDetails.forEach((q3, i3) => {
                                    if (q3.questionCategory === "Critical" && q3.valuation === "4") {
                                        criticalValueCount++;
                                    }
                                })
                            })
                            scoringDetails.push({
                                "sections": q1.auditTypeName,
                                "value1": value1Count,
                                "value2": value2Count,
                                "value3": value3Count,
                                "value4": value4Count,
                                "foodValue1": foodValue1Count,
                                "foodValue2": foodValue2Count,
                                "foodValue3": foodValue3Count,
                                "foodValue4": foodValue4Count,
                                "score": `${(getScore(foodValue1Count, foodValue2Count, foodValue3Count, foodValue4Count)).toFixed()}%`,
                                "total": criticalValueCount !== 0 ? "Critical" : checkStandard(getScore(foodValue1Count, foodValue2Count, foodValue3Count, foodValue4Count)),
                            })
                            value1Count = 0;
                            value2Count = 0;
                            value3Count = 0;
                            value4Count = 0;
                            foodValue1Count = 0;
                            foodValue2Count = 0;
                            foodValue3Count = 0;
                            foodValue4Count = 0;
                            questionsCount = 0;
                        }
            })

            // console.log(finalAns);
            setFinalData(finalAns)
            if (localStorage.getItem('auditType') == '2') {
                let totalV1 = 0;
                let totalV2 = 0;
                let totalV3 = 0;
                let totalV4 = 0;
                let total = 0;
                scoringDetails.forEach((sec) => {
                    totalV1 = sec.Ok + totalV1;
                    totalV2 = sec.NotOk + totalV2;
                    totalV3 = sec.Good + totalV3;
                    totalV4 = sec.value4 + totalV4;
                    total = sec.total + total;
                })
                totalQuestions = totalV1 + totalV2 + totalV3;
                let totalPercentage = 0;
                if (total !== 0) {
                    totalPercentage = ((totalV1) / total) * 100;
                }
                if (totalPercentage >= 75) {
                    if (isPropertyPassOrFail) {
                        setPassOrFail("Fail")
                    }
                    else {
                        setPassOrFail("Pass")
                    }
                }
                else {
                    setPassOrFail("Fail")
                }

                scoringDetails.push({
                    "sections": "TOTAL SUMMARY PROPERTY",
                    "total": total,
                    "Ok": totalV1,
                    "NotOk": totalV2,
                    "Good": totalV3,
                    "value4": totalV4,
                    "percentage": `${totalPercentage.toFixed()}%`
                })
                setrowData(scoringDetails)
            } else if (localStorage.getItem('auditType') == '3') {
                let totalV1 = 0;
                let totalV2 = 0;
                let totalV3 = 0;
                scoringDetails.forEach((sec) => {
                    totalV1 = sec.Ok + totalV1;
                    totalV2 = sec.NotOk + totalV2;
                    totalV3 = sec.NotApplicable + totalV3;
                })
                totalQuestions = totalV1 + totalV2 + totalV3;
                scoringDetails.push({
                    "sections": "AUDIT RESULT TOTAL",
                    "Ok": totalV1,
                    "NotOk": totalV2,
                    "NotApplicable": totalV3,
                })
                setrowData(scoringDetails)
            }
            else if (localStorage.getItem('auditType') == '4') {
                let totalV1 = 0;
                let totalV2 = 0;
                let totalV3 = 0;
                let totalPriority = 0;
                let totalRiskRating = 0;
                let totalScoringForSecurity = 0;
                scoringDetails.forEach((sec) => {
                    totalV1 = sec.Ok + totalV1;
                    totalV2 = sec.NotOk + totalV2;
                    totalV3 = sec.NotApplicable + totalV3;
                    totalPriority = sec.priority + totalPriority;
                    totalRiskRating = sec.riskRating + totalRiskRating;
                    totalScoringForSecurity = sec.scoring + totalScoringForSecurity;
                })
                scoringDetails.push({
                    "sections": "TOTAL",
                    "Ok": totalV1,
                    "NotOk": totalV2,
                    "NotApplicable": totalV3,
                    "priority": totalPriority,
                    "riskRating": totalRiskRating,
                    "scoring": totalScoringForSecurity
                })
                setrowData(scoringDetails)
                let TotalScore = 0;
                if (totalRiskRating !== 0) {
                    TotalScore = (totalScoringForSecurity / totalRiskRating) * 100;
                }
                setSecurityTotal(`${TotalScore.toFixed()}%`)
                if (TotalScore > 85) {
                    setIsSecurityPassOrFail("Passed");
                }
                else {
                    setIsSecurityPassOrFail("Failed");
                }

            }
            else {
                let totalV1 = 0;
                let totalV2 = 0;
                let totalV3 = 0;
                let totalV4 = 0;
                let totalFoodV1 = 0;
                let totalFoodV2 = 0;
                let totalFoodV3 = 0;
                let totalFoodV4 = 0;
                scoringDetails.forEach((sec) => {
                    totalV1 = sec.value1 + totalV1;
                    totalV2 = sec.value2 + totalV2;
                    totalV3 = sec.value3 + totalV3;
                    totalV4 = sec.value4 + totalV4;
                    totalFoodV1 = sec.foodValue1 + totalFoodV1;
                    totalFoodV2 = sec.foodValue2 + totalFoodV2;
                    totalFoodV3 = sec.foodValue3 + totalFoodV3;
                    totalFoodV4 = sec.foodValue4 + totalFoodV4;
                })
                totalQuestions = totalV1 + totalV2 + totalV3 + totalV4;
                let totalCriticalValueCount = 0;
                questionsList.forEach((q1, i1) => {
                    q1.auditSubTypeQuestionDetails.forEach((q2, i2) => {
                        q2.auditSubtypeUIDDetails.forEach((q3, i) => {
                            if (q3.questionCategory === "Critical" && q3.valuation === "4") {
                                totalCriticalValueCount++;
                            }

                        })
                    })
                })
                scoringDetails.push({
                    "sections": "AUDIT RESULT TOTAL",
                    "value1": totalV1,
                    "value2": totalV2,
                    "value3": totalV3,
                    "value4": totalV4,
                    "score": `${(getScore(totalFoodV1, totalFoodV2, totalFoodV3, totalFoodV4)).toFixed()}%`,
                    "total": totalCriticalValueCount !== 0 ? "Critical" : checkStandard(getScore(totalFoodV1, totalFoodV2, totalFoodV3, totalFoodV4)),
                })
                setrowData(scoringDetails)
            }
            actionPlanData();

        })();
    }, [])

    async function actionPlanData() {
        let data = {
            "auditUID": localStorage.getItem("auditUID")
        }
        await FoodAudit.setItem("actPlanRowData", [])
        const reviewerComments = await apiPost(getApiName(localStorage.getItem('auditType')), data);
        if (reviewerComments.data.message === 'Success') {
            console.log(reviewerComments.data.data);
            setActPlanRowData(reviewerComments.data.data)
        }
    }

    function getApiName(value) {
        switch (value) {
            case "1":
                return (
                    config.getfoodreviewquestions
                );
            case "2":
                return (
                    config.getpropertyreviewquestions
                );
            case "3":
                return (
                    config.getdmreviewquestions
                );
            case "4":
                return (
                    config.getsecurityreviewquestions
                );
            default:
                throw new Error("Unknown step");
        }
    }

    const getScore = (v1, v2, v3, v4) => {
        let total = v1 + v2 + v3 + v4;
        if (total === 0) {
            return 0;
        }
        else
            return ((v1 * 100) + (v2 * 66) + (v3 * 33) + (v4 * -100)) / total;
    }

    const checkStandard = (value) => {
        if (value.toFixed() >= 90 && value.toFixed() <= 100)
            return "Standard"
        else if (value.toFixed() >= 80 && value.toFixed() <= 89)
            return "Below Standard"
        else if (value.toFixed() >= 50 && value.toFixed() <= 79)
            return "Serious"
        else if (value.toFixed() > 0 && value.toFixed() <= 49)
            return "Critical"
        else
            return " "
    }
    const checkStatusStandard = (value) => {
        switch (value) {
            case '1':
                return (
                    "standard"
                );
            case '2':
                return (
                    "standard"
                );
            case '3':
                return (
                    "below critical"
                );
            case '4':
                return (
                    "serious"
                );
            case '5':
                return (
                    "critical"
                );
            case '':
                return (
                    "Not applicable"
                );
            default:
                throw new Error("Unknown step");
        }
    }

    const onGridReady = (params) => {
        setgridApi(params.api);
        // params.api.sizeColumnsToFit();
        // window.onresize = () => {
        //   params.gridApi.sizeColumnsToFit();
        // }
        params.api.sizeColumnsToFit();
    }
    const onActPlanGridReady = (params) => {
        setActPlanGridApi(params.api);
        // params.api.sizeColumnsToFit();
        // window.onresize = () => {
        //   params.gridApi.sizeColumnsToFit();
        // }
        params.api.sizeColumnsToFit();
    }


    async function backToQuestion() {
        localStorage.setItem("isCritical", isCritical);
        localStorage.setItem("isBackfromScoring", true);
        history.push({ pathname: "/app/ui/questionnaire", state: { answerresponseDetails: await FoodAudit.getItem("answerDetails"), questionDetails: await FoodAudit.getItem("questionDetails"), isBackFromScoring: true, isCritical: isCritical } })
        localStorage.setItem("localComments", localComments)
    }



    async function handleExitButton() {
        if (localStorage.getItem('internetFlag') == 'true') {
            if (localStorage.getItem('disableCheckBox') != 'true') {
                await ApiCall();
            }
            setIsExitPopup(true);
        }
        else {
            setIsExitPopup(true);
        }

    }



    async function ApiCall() {
        let storedAnswers = await FoodAudit.getItem("storedAnswers") === null ? [] : await FoodAudit.getItem("storedAnswers");
        let response;
        if (storedAnswers) {
            if (storedAnswers.length > 0) {
                const form_data = new FormData();
                storedAnswers.forEach((key, index) => {
                    if (index == 0) {
                        form_data.append('userUID', localStorage.getItem('userUID'));
                        form_data.append('transactionUID', localStorage.getItem('auditUID'));
                    }
                    if (localStorage.getItem('auditType') == '2') {
                        form_data.append('auditSubtypeUID[]', key.auditSubtypeUID);
                    }
                    form_data.append('valuation[]', key.valuation === null ? '' : key.valuation);
                    form_data.append('comments[]', key.comments === null || key.comments === 'null' ? '' : key.comments);
                    if (localStorage.getItem('auditType') == '1') {
                        form_data.append('status[]', key.status === null ? '' : key.status);
                    }
                    form_data.append('observationUID[]', key.observationUID);
                    form_data.append('questionnaireUID[]', key.questionnaireUID);
                    if (storedAnswers[index].images.length > 0) {
                        for (let i = 0; i < storedAnswers[index].images.length; i++) {
                            form_data.append(`images_${key.questionnaireUID}[]`, storedAnswers[index].images[i]);
                        }
                    } else {
                        form_data.append(`images_${key.questionnaireUID}[]`, []);
                    }
                    if (storedAnswers[index].videos.length > 0) {
                        for (let i = 0; i < storedAnswers[index].videos.length; i++) {
                            form_data.append(`videos_${key.questionnaireUID}[]`, storedAnswers[index].videos[i]);
                        }
                    } else {
                        form_data.append(`videos_${key.questionnaireUID}[]`, []);
                    }
                });
                if (localStorage.getItem('auditType') == '2') {
                    response = await apiFormDataPost(config.paInsertAuditResponse, form_data);
                } else if (localStorage.getItem('auditType') == '3') {
                    response = await apiFormDataPost(config.dmInsertAuditResponse, form_data);
                } else if (localStorage.getItem('auditType') == '4') {
                    response = await apiFormDataPost(config.ssInsertAuditResponse, form_data);
                } else {
                    response = await apiFormDataPost(config.insertFAAuditResponse, form_data);
                }
                setResponseStatus(response.data.data[0].status)
                if (response.data.data[0].status === "Updated Successfully") {
                    await FoodAudit.removeItem("answerresponseDetails");
                    await FoodAudit.removeItem("questionDetails");
                    await FoodAudit.removeItem("storedAnswers");
                }
            }
            return response.data.data[0].status
        }
    }

    async function updateAnswer(values) {
        let storedAnswers = await FoodAudit.getItem("storedAnswers") === null ? [] : await FoodAudit.getItem("storedAnswers");
        if (storedAnswers && storedAnswers.length > 0) {
            const response = await ApiCall();
            return response;
        } else {
            return "Updated Successfully";
        }
    }
    let validationSchema;
    if (localStorage.getItem("roleType") === "Reviewer") {
        validationSchema = Yup.object().shape({
            actionPlanComments: Yup.string().required("required Action Plan"),
        });
    } else {
        validationSchema = Yup.object().shape({
            reviewer: Yup.string().required("required Reviewer"),
            localComments: Yup.string().required("required Local Comments"),
            closingMeetingAttendees: Yup.string().required("required Closing Meeting Attendees")
        });
    };
    let reviewerActionValidationSchema = Yup.object().shape({
        actionplanComments: Yup.string().required("required Comments")
    });
    let storeActionValidationSchema = Yup.object().shape({
        assignTo: Yup.string().required("required who"),
        storecomments: Yup.string().required("required what")
    });

    async function closeExitPopup() {
        await FoodAudit.removeItem("answerresponseDetails");
        await FoodAudit.removeItem("questionDetails");
        await FoodAudit.removeItem("storedAnswers");
        history.push({ pathname: "/app/dashboard" });
    }
    async function closeReopenPopup() {
        if (localStorage.getItem('internetFlag') == 'true') {
            let data = {
                "auditUID": localStorage.getItem("auditUID"),
                "questionUID": reviewerAction.questionnaireUID
            }
            const response = await apiPost(config.reopenaudit, data);
            // console.log(response)

            if (response.data.status == true) {
                // console.log(response)
                toast.success("Question Reopen Successfully");
                setIsReopenPopup(false)
                actionPlanData()
                scrollToSelectedRow()
            }
            else {
                setIsReopenPopup(false);
                toast.success("Something went wrong. Try again Later");
            }
        } else {
            setIsReopenPopup(false);
            toast.success("You are not online to reopen the question");
        }

    }
    async function closeCompletePopup() {
        if (localStorage.getItem('internetFlag') == 'true') {
            let data = {
                "auditUID": localStorage.getItem("auditUID"),
                "questionUID": reviewerAction.questionnaireUID
            }
            const response = await apiPost(config.markcomplete, data);
            // console.log(response)

            if (response.data.status == true) {
                toast.success("Question Completed Successfully");
                setIsCompletePopup(false);
                actionPlanData()
                scrollToSelectedRow()
            }
            else {
                toast.success("Something went wrong. Try again Later");
                setIsCompletePopup(false);
            }
        } else {
            setIsCompletePopup(false);
            toast.success("You are not online to complete the question");
        }

    }

    function scrollToSelectedRow() {
        // console.log("gridapi values", gridApi);
        //console.log("gridapi count", gridApi.getInfiniteRowCount());
        gridApi.ensureIndexVisible(selectedRow.rowIndex, 'middle');
    }

    async function closeStoreActionPopup(values) {
        if (localStorage.getItem('internetFlag') == 'true') {
            let data = {
                "auditUID": localStorage.getItem("auditUID"),
                "questionUID": reviewerAction.questionnaireUID,
                "assignTo": values.assignTo,
                "dueDate": dueDate,
                "comments": values.storecomments
            }
            const response = await apiPost(config.addstoreusercomments, data);
            // console.log(response)

            if (response.data.status == true) {
                if (response.data.data[0].status === "Updated Successfully") {
                    // console.log(response)
                    toast.success("Comment Saved Successfully");
                    setIsStoreActionPopup(false);
                    actionPlanData()
                }
            }
            else {
                toast.success("Something went wrong. Try again Later");
                setIsStoreActionPopup(false);
            }
        } else {
            toast.success("you are not online to proceed further");
            setIsStoreActionPopup(false);
        }

    }
    async function closeReviewerActionPopup(values) {
        if (localStorage.getItem('internetFlag') == 'true') {
            // let data = {
            //     "auditUID": localStorage.getItem("auditUID"),
            //     "questionUID": reviewerAction.questionnaireUID,
            //     "actionplancomments": values.actionplanComments,
            //     "observationUID":values.observationUID,
            //     "uploadimage":values.uploadimage,
            //     "uploadvideo":values.uploadvideo
            // }
            console.log(values)
            const form_data = new FormData();
            if (values.uploadimage.length > 0) {
                for (let i = 0; i < values.uploadimage.length; i++) {
                    form_data.append('uploadimage[]', values.uploadimage[i]);
                }
            }
            if (values.uploadvideo.length > 0) {
                for (let i = 0; i < values.uploadvideo.length; i++) {
                    form_data.append('uploadvideo[]', values.uploadvideo[i]);
                }
            }
            form_data.append('questionUID', reviewerAction.questionnaireUID);
            form_data.append('auditUID', localStorage.getItem("auditUID"));
            form_data.append('userUID', localStorage.getItem("userUID"));
            form_data.append('observationUID', reviewerAction.observationUID);
            form_data.append('actionplancomments', values.actionplanComments);

            const response = await apiFormDataPost(config.updateactionplan, form_data);
            // const response = await apiPost(config.updateactionplan, data);
            // // console.log(response)

            if (response.data.status == true) {
                if (response.data.data[0].status === "Updated Successfully") {
                    // console.log(response)
                    toast.success("Comment Saved Successfully");
                    setIsReviewerActionPopup(false)
                    actionPlanData()
                }
            }
            else {
                setIsReviewerActionPopup(false);
                toast.success("Something went wrong. Try again later");
            }
        } else {
            setIsReviewerActionPopup(false);
            toast.success("you are not online to proceed further");
        }

    }
    async function closeStoreUploadPopup() {
        if (localStorage.getItem('internetFlag') == 'true') {
            if (uploadFiles.length > 5) {
                toast.error("Maximum 5 files are allowed to upload. Kindly upload within max limit to proceed further");
                return;
            }
            const form_data = new FormData();
            if (uploadFiles.length > 0) {
                for (let i = 0; i < uploadFiles.length; i++) {
                    form_data.append('uploadFile[]', uploadFiles[i]);
                }
            }
            form_data.append('questionnaireUID', reviewerAction.questionnaireUID);
            form_data.append('auditUID', localStorage.getItem("auditUID"));
            form_data.append('userUID', localStorage.getItem("userUID"));
            form_data.append('observationUID', reviewerAction.observationUID);

            const response = await apiFormDataPost(config.uploadquestionimage, form_data);
            // console.log(response)

            if (response.data.status == true) {
                if (response.data.message === "Success") {
                    toast.success("Files uploaded Successfully");
                    setIsStoreUploadPopup(false);
                    actionPlanData()
                }
            }
            else {
                toast.error("Something went wrong. Try again Later");
                setIsStoreUploadPopup(false);
            }
        } else {
            toast.error("you are not online to upload files");
            setIsStoreUploadPopup(false);
        }

    }

    function getActionPlanImageFiles() {
        if (reviewerAction?.uploadimage) {
            if (reviewerAction?.uploadimage.length !== 0) {
                let array = [];
                reviewerAction.uploadimage.forEach((item) => {
                    // let fileName = item.fileName.split(".")
                    // var file = new File([`${imageURL}/${item.imgfilepath}/${item.fileName}`], item.fileName, { type: `image/${fileName[1]}`, });
                   var file = `${imageURL}/${item.imgfilepath}/${item.fileName}`;
                    array.push(file);
                })
                return array;
            }
            else {
                return [];
            }
        }
        else
            return [];
    }
    function getActionPlanVideoFiles() {
        if (reviewerAction?.uploadvideo) {
            if (reviewerAction?.uploadvideo.length !== 0) {
                let array = [];
                reviewerAction.uploadvideo.forEach((item) => {
                    // let fileName = item.fileName.split(".")
                    // var file = new File([`${imageURL}/${item.videofilepath}/${item.fileName}`], item.fileName, {
                    //     type: `video/${fileName[1]}`,
                    // });
                   var file = `${imageURL}/${item.videofilepath}/${item.fileName}`;
                    array.push(file);
                })
                return array;
            }
            else {
                return [];
            }
        }
        else
            return [];
    }
    function getInputUploadfiles() {
        if (reviewerAction?.uploadedFiles) {
            if (reviewerAction?.uploadedFiles.length !== 0) {
                let array = [];
                reviewerAction.uploadedFiles.forEach((item) => {
                    let fileName = item.fileName.split(".")
                    var file;
                    if (item.fileType === "Video") {
                        file = `${imageURL}/${item.videofilepath}/${item.fileName}`
                    } else {
                        file = `${imageURL}/${item.imgfilepath}/${item.fileName}`
                    }

                    array.push(file);
                })
                // console.log(array);
                return array;
            }
            else {
                return [];
            }
        }
        else
            return [];

    }

    async function sendToStore() {
        if (localStorage.getItem('disableCheckBox') == 'true') {
            if (actPlanRowData.length === 0) {
                toast.error("Cannot proceed further, since there is no action plan comments");
                return;
            }
            let isCommentsAllFilled = false;
            let isCommentsAllFilledCount = 0;
            if (actPlanRowData.length > 0) {
                actPlanRowData.forEach((comment) => {
                    if (comment.actionplanStatus === "Action Plan Inprogress") {
                        isCommentsAllFilledCount++;
                    }
                })
            }
            if (isCommentsAllFilledCount === actPlanRowData.length) {
                isCommentsAllFilled = false;
            } else {
                isCommentsAllFilled = true;
            }

            if (localStorage.getItem('internetFlag') == 'true') {
                if (isCommentsAllFilled === false) {
                    let data = {
                        "userUID": localStorage.getItem("userUID"),
                        "transactionUID": localStorage.getItem("transactionUID"),
                        "actionplanComments": null
                    }
                    setLoading(true);
                    const response = await apiPost(config.sentAuditor, data);
                    setLoading(false);
                    toast.success(response.data.message + " Thank you for your Review");
                    setTimeout(() => {
                        history.push({ pathname: "/app/dashboard" });
                    }, 3000);
                } else {
                    toast.error("Kindly answer all the Comments to send for store");
                }

            }
            else {
                toast.error("You are not online to send the action plan");
                // setTimeout(() => {
                //     history.push({ pathname: "/app/dashboard" });
                // }, 3000);
            }
        }
    }

    async function sendBackToAuditor() {
        if (localStorage.getItem('internetFlag') == 'true') {
            if (reviewerMessageToAuditor !== "") {
                let data = {
                    "transactionUID": localStorage.getItem("transactionUID"),
                    "reviewerComments": reviewerMessageToAuditor
                }
                setLoading(true);
                const response = await apiPost(config.sendreviewerresponsetoaudit, data);
                setLoading(false);
                toast.success(response.data.message);
                setTimeout(() => {
                    history.push({ pathname: "/app/dashboard" });
                }, 3000);
            } else {
                toast.error("Kindly enter the message to send back to Auditor");
            }
        }
        else {
            toast.error("You are not online to send the action plan");
            // setTimeout(() => {
            //     history.push({ pathname: "/app/dashboard" });
            // }, 3000);
        }
    }

    async function handleSubmit(values) {
        if (localStorage.getItem('disableCheckBox') != 'true') {
            let data = {
                "userUID": localStorage.getItem("userUID"),
                "transactionUID": localStorage.getItem("transactionUID"),
                "localComments": localComments,
                "closingmeetingAttendees": closingComments,
                "reviewerUID": reviewer
            }
            if (localStorage.getItem('internetFlag') == 'true') {
                setLoading(true);
                const updated = await updateAnswer(values);
                // console.log(updated)
                if (updated === "Updated Successfully") {
                    const response = await apiPost(config.sentReview, data);
                    setLoading(false);
                    toast.success(response.data.message + " Thank you for your answers");
                    setTimeout(() => {
                        history.push({ pathname: "/app/dashboard" });
                    }, 3000);
                } else if (updated === "Updated Failed") {
                    setLoading(false);
                    toast.error(updated + " Can't send to Auditor. Please try again later!");
                    setTimeout(() => {
                        history.push({ pathname: "/app/dashboard" });
                    }, 3000);
                }
                else {
                    setLoading(false);
                    toast.error("Something went wrong! Please try again later!");
                    setTimeout(() => {
                        history.push({ pathname: "/app/dashboard" });
                    }, 3000);
                }
            }
            else {
                toast.error("You are not online to send for Review");
            }
        }
    }

    const reactToPrintContent = React.useCallback(() => {
        return gridRef.current;
    }, [gridRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "Score",
        removeAfterPrint: true
    });

    function goToDashboard() {
        if (localStorage.getItem('internetFlag') == 'true') {
            history.push({ pathname: "/app/dashboard" });
        } else {
            toast.error("You are not online to go to Dashboard");
        }
    }

    async function completeActionAudit() {
        let isCommentsAllFilled = false;
        let isCommentsAllFilledCount = 0;
        if (actPlanRowData.length > 0) {
            actPlanRowData.forEach((comment) => {
                if (comment.actionplanStatus === "Action Plan Completed") {
                    isCommentsAllFilledCount++;
                }
            })
        }
        if (isCommentsAllFilledCount === actPlanRowData.length) {
            isCommentsAllFilled = false;
        } else {
            isCommentsAllFilled = true;
        }
        if (localStorage.getItem('internetFlag') == 'true') {
            if (isCommentsAllFilled === false) {
                let data = {
                    "auditUID": localStorage.getItem("auditUID")
                }
                setLoading(true);
                const response = await apiPost(config.completeaudit, data);
                setLoading(false);
                toast.success("Successfully Completed Audit");
                setTimeout(() => {
                    history.push({ pathname: "/app/dashboard" });
                }, 3000);
            } else {
                toast.error("Kindly mark as Complete for all the questions to proceed further");
            }

        }
        else {
            toast.error("You are not online to send Complete Audit");
            // setTimeout(() => {
            //     history.push({ pathname: "/app/dashboard" });
            // }, 3000);
        }

    }

    function handleChartValueChange(e) {
        setChartValue(e.target.value)
    }


    const onFirstDataRendered = useCallback((params) => {
        params.api.expandAll();
    }, []);


    const onBtPrint = () => {
        const jsonvalue1 = columnDef.map((q, i) => {
            return q.field
        })
        console.log(jsonvalue1)
        printJS({
            printable: rowData,
            type: 'json',
            properties: jsonvalue1,
            header: `<h3 class='custom-h3'>Score List</h3>`,
            style: `.custom-h3 { color: red; }`
        },)
    }

    async function actionPlanOnBtPrint() {
        const jsonvalue2 = localStorage.getItem("roleType") === "Reviewer" ? actPlanReviewerColumnDef : actPlanStoreColumnDef
        const jsonvalue4 = jsonvalue2.map((q, i) => {
            return q.field
        })
        printJS({
            printable: actPlanRowData,
            type: 'json',
            properties: jsonvalue4,
            header: `<h3 class='custom-h3'>Action Plan List</h3>`,
            style: `.custom-h3 { color: red; }`

        })
    }

    
  function openImageInNewTab(imageUrl) {
    // Create a new tab with the image URL
    window.open(imageUrl, "_blank");
  }

    return (
        <>
            {loading ? (
                <>
                    <ThreeDots

                        color="#00BFFF"
                        height={100}
                        width={100}
                        wrapperStyle={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "13rem"
                        }}
                    />
                </>
            ) : (
                <>
                    <Box>
                        <div ref={gridRef}>
                            <div style={{ display: 'flex', paddingTop: '70px' }}>
                                <div style={{ marginRight: 'auto' }}>
                                    <PageTitle title="Score" />
                                </div>
                                {auditStatus === "Audit Completed" || localStorage.getItem("roleType") === "Reviewer" || localStorage.getItem("roleType") === "Store User" ? (
                                    <div>
                                        <Button
                                            classes={{ root: classes.button }}
                                            variant="contained"
                                            // size="small"
                                            color="primary"
                                            onClick={goToDashboard}
                                        >
                                            Go to Dashboard
                                        </Button>
                                    </div>
                                ) : null}

                                <div>
                                    <Tooltip title="Print">
                                        <IconButton onClick={onBtPrint}>
                                            <PrintIcon sx={{ color: "#0257a7", fontSize: '33px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>


                            {/* <Grid container sx={{ paddingTop: "70px", }}>
                                <Grid item lg={3} md={3} sm={3} xs={12} sx={{ textAlign: 'start', paddingLeft: '0', }}>
                                    <PageTitle title="Score" />
                                </Grid>
                                {auditStatus === "Audit Completed" || localStorage.getItem("roleType") === "Reviewer" || localStorage.getItem("roleType") === "Store User" ? (

                                    <Grid item lg={7} md={7} sm={6} xs={12} sx={{ textAlign: 'end', paddingRight: '0', paddingBottom: '15px' }}>
                                        <Button
                                            classes={{ root: classes.button }}
                                            variant="contained"
                                            // size="small"
                                            color="primary"
                                            onClick={goToDashboard}
                                        >
                                            Go to Dashboard
                                        </Button>
                                    </Grid>

                                ) : null}
                                {(auditStatus === "Audit Completed" || localStorage.getItem("roleType") === "Reviewer" || localStorage.getItem("roleType") === "Store User") && localStorage.getItem('auditType') !== '1' ? (
                                    <Grid item lg={1} md={1} sm={1} xs={12} sx={{ textAlign: 'end', paddingRight: '0', paddingBottom: '15px' }}>
                                        <Button
                                            // classes={{ root: classes.button }}
                                            classes={{ root: classes.button }}
                                            variant="contained"
                                            // size="small"
                                            color="primary"
                                            onClick={backToQuestion}
                                        >
                                            Back
                                        </Button>
                                    </Grid>

                                ) : null}
                                {(localStorage.getItem("roleType") !== "Reviewer" && localStorage.getItem("roleType") !== "Store User" && localStorage.getItem("auditStatus") !== "Audit Completed") ? (
                                    <Grid item lg={9} md={9} sm={9} xs={12} sx={{ justifyContent: "end", alignItems: "center", display: "flex" }}>
                                        <Tooltip title="Print">
                                            <IconButton onClick={onBtPrint}>
                                                <PrintIcon sx={{ color: "#0257a7", fontSize: '33px' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                ) : (<Grid item lg={1} md={1} sm={2} xs={12} sx={{ justifyContent: "end", alignItems: "center", display: "flex" }}>
                                    <Tooltip title="Print">
                                        <IconButton onClick={onBtPrint}>
                                            <PrintIcon sx={{ color: "#2196f3", fontSize: '33px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>)}

                            </Grid> */}



                            <Box sx={{ width: '100%', backgroundColor: "white", borderRadius: "5px", paddingBottom: "1rem" }}>
                                <div className="ag-theme-alpine" id="myGrid" style={{ height: 513, width: '100%', border: 'none !important' }}>
                                    <AgGridReact

                                        defaultColDef={{
                                            editable: false,
                                            //  enableRowGroup: true,
                                            //   enablePivot: true,
                                            //  enableValue: true,
                                            // sortable: true,
                                            resizable: true,
                                            // filter: true,
                                            // floatingFilter: true,
                                            // filter: 'agTextColumnFilter',
                                            minWidth: 100,
                                            // cellStyle: { textAlign: 'center' }
                                        }}
                                        //  suppressRowClickSelection={true}
                                        //  groupSelectsChildren={true}
                                        //  debug={true}
                                        //  rowSelection={'multiple'}
                                        // /*  rowGroupPanelShow={'always'} */
                                        // pivotPanelShow={'always'}
                                        //  enableRangeSelection={true}
                                        //  pagination={true}
                                        onGridReady={onGridReady}
                                        // onFirstDataRendered={onFirstDataRendered}
                                        columnDefs={columnDef}
                                        rowData={rowData}

                                    />
                                </div>
                                {localStorage.getItem('auditType') == '2' ? (
                                    <Grid container>
                                        <Grid item xs={12} className={classes.statusGrid}>
                                            <Typography variant='h6'>
                                                Pass / Fail Status: {passOrFail}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                ) : null}
                                {localStorage.getItem('auditType') == '4' ? (
                                    <Grid container>
                                        <Grid item xs={12} className={classes.statusGrid}>
                                            <Typography variant='h6'>
                                                Rating : {securityTotal}  {isSecurityPassOrFail}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                ) : null}
                                {auditStatus !== "Audit Completed" && localStorage.getItem("roleType") === "Reviewer" ? (
                                    <Grid sx={{ marginTop: "10px", marginBottom: "10px" }} container>
                                        <Grid item lg={6} md={6} sm={6} xs={12} sx={{ paddingLeft: "0px" }} >
                                            <TextField
                                                label="Message"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                onChange={(e) => {
                                                    setReviewerMessageToAuditor(e.target.value)
                                                }}

                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: "10px" }}>
                                            <Button
                                                classes={{ root: classes.auditorButton }}
                                                type="button"
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                onClick={sendBackToAuditor}
                                            >
                                                Send Back to Auditor
                                            </Button>
                                        </Grid>
                                    </Grid>) : (null)}
                                {auditStatus === "Audit Completed" && localStorage.getItem("roleType") !== "Reviewer" && localStorage.getItem("roleType") !== "Store User" ? (
                                    <div style={{ paddingTop: '5px' }}>
                                        <Button
                                            // classes={{ root: classes.button }}
                                            classes={{ root: classes.button }}
                                            variant="contained"
                                            // size="small"
                                            color="primary"
                                            onClick={backToQuestion}
                                        >
                                            Back
                                        </Button>
                                    </div>

                                ) : null}
                            </Box>
                            {localStorage.getItem('auditType') == '2' || localStorage.getItem('auditType') == '3' || localStorage.getItem('auditType') == '4' ? (
                                <Box sx={{ width: '100%', paddingBottom: "4rem", paddingTop: "1rem" }}>
                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <InputLabel>Chart Types</InputLabel>
                                        <Select
                                            value={chartValue}
                                            label="Chart Types"
                                            onChange={handleChartValueChange}
                                        >
                                            <MenuItem value={1}>Stacked Area</MenuItem>
                                            <MenuItem value={2}>Line</MenuItem>
                                            <MenuItem value={3}>Bar</MenuItem>
                                            <MenuItem value={4}>Stacked Bar</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <ResponsiveContainer width="100%" height={400}>
                                        {chartValue === 1 ? (<AreaChart
                                            width={500}
                                            height={400}
                                            data={rowData}
                                            margin={{
                                                top: 10,
                                                right: 30,
                                                left: 0,
                                                bottom: 0
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="sections" />
                                            <YAxis />
                                            <Tool />
                                            <Legend />
                                            <Area
                                                type="monotone"
                                                dataKey="Ok"
                                                stackId="1"
                                                stroke="#82ca9d"
                                                fill="#82ca9d"
                                            />
                                            <Area
                                                type="monotone"
                                                dataKey="NotOk"
                                                stackId="1"
                                                stroke="#8884d8"
                                                fill="#8884d8"
                                            />
                                            <Area
                                                type="monotone"
                                                dataKey={localStorage.getItem('auditType') == '2' ? "Good" : "NotApplicable"}
                                                stackId="1"
                                                stroke="#ffc658"
                                                fill="#ffc658"
                                            />
                                        </AreaChart>) : null}

                                        {chartValue === 2 ? (<LineChart
                                            width={500}
                                            height={300}
                                            data={rowData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="sections" />
                                            <YAxis yAxisId="left" />
                                            <YAxis yAxisId="right" orientation="right" />
                                            <Tool />
                                            <Legend />
                                            <Line
                                                yAxisId="left"
                                                type="monotone"
                                                dataKey="Ok"
                                                stroke="#82ca9d"
                                                activeDot={{ r: 8 }}
                                            />
                                            <Line
                                                yAxisId="left"
                                                type="monotone"
                                                dataKey="NotOk"
                                                stroke="#8884d8"
                                                activeDot={{ r: 8 }}
                                            />
                                            <Line
                                                yAxisId="right"
                                                type="monotone"
                                                dataKey={localStorage.getItem('auditType') == '2' ? "Good" : "NotApplicable"}
                                                stroke="#ffc658"
                                                activeDot={{ r: 8 }}
                                            />
                                        </LineChart>) : null}

                                        {chartValue === 3 ? (<BarChart
                                            width={500}
                                            height={300}
                                            data={rowData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="sections" />
                                            <YAxis />
                                            <Tool />
                                            <Legend />
                                            <Bar dataKey="Ok" fill="#82ca9d" />
                                            <Bar dataKey="NotOk" fill="#8884d8" />
                                            <Bar dataKey={localStorage.getItem('auditType') == '2' ? "Good" : "NotApplicable"} fill="#ffc658" />
                                        </BarChart>) : null}

                                        {chartValue === 4 ? (<BarChart
                                            width={500}
                                            height={300}
                                            data={rowData}
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="sections" />
                                            <YAxis />
                                            <Tool />
                                            <Legend />
                                            <Bar dataKey="Ok" stackId="a" fill="#82ca9d" />
                                            <Bar dataKey="NotOk" stackId="a" fill="#8884d8" />
                                            <Bar dataKey={localStorage.getItem('auditType') == '2' ? "Good" : "NotApplicable"} stackId="a" fill="#ffc658" />
                                        </BarChart>) : null}

                                    </ResponsiveContainer>
                                </Box>
                            ) : null}

                            {(localStorage.getItem("roleType") === "Reviewer" || localStorage.getItem("roleType") === "Store User") && localStorage.getItem('auditType') == '1' ? (
                                <>
                                    <Box sx={{ width: '100%', backgroundColor: "white", borderRadius: "5px", marginTop: "15px", paddingBottom: "1rem" }}>
                                        <Grid sx={{ marginTop: "10px", marginBottom: "10px" }} container>
                                            <Grid item lg={6} md={6} sm={6} xs={12} sx={{ paddingLeft: "0px" }} >
                                                <Typography variant='h6'>
                                                    Food CheckList Action Plan
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                sx={{ justifyContent: "end", display: "flex", paddingRight: "0px", paddingTop: '5px' }} item lg={6} md={6} sm={6} xs={12}>
                                                {localStorage.getItem("roleType") === "Store User" ? (
                                                    <Button
                                                        classes={{ root: classes.button }}
                                                        type="button"
                                                        variant="contained"
                                                        size="small"
                                                        color="primary"
                                                        disabled={auditStatus === "Audit Completed" ? true : false}
                                                        onClick={completeActionAudit}
                                                    >
                                                        Complete Audit
                                                    </Button>) : (null)}
                                                {localStorage.getItem("roleType") === "Reviewer" ? (<Button
                                           
                                                    classes={{ root: classes.button }}
                                                    type="button"
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    disabled={auditStatus === "Audit Completed" ? true : false}
                                                    onClick={sendToStore}
                                                >
                                                    Send To Store
                                                </Button>) : (null)}
                                                <Tooltip title="Print">     
                                                    <IconButton onClick={actionPlanOnBtPrint} >
                                                        <PrintIcon sx={{ color: "#0257a7" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>



                                        < div className="ag-theme-alpine" id="myGrid" style={{ height: 403, width: '100%', border: 'none !important' }}>
                                            <AgGridReact

                                                defaultColDef={{

                                                    //  enableRowGroup: true,
                                                    //   enablePivot: true,
                                                    //  enableValue: true,
                                                    // sortable: true,
                                                    resizable: true,
                                                    minWidth: 50,
                                                    // filter: true,
                                                    // floatingFilter: true,
                                                    // filter: 'agTextColumnFilter',
                                                    //minWidth: 100,
                                                    // cellStyle: { textAlign: 'center' }
                                                }}
                                                onGridReady={onGridReady}
                                                columnDefs={localStorage.getItem("roleType") === "Reviewer" ? actPlanReviewerColumnDef : actPlanStoreColumnDef}
                                                rowData={actPlanRowData}

                                            />
                                        </div>
                                        {auditStatus === "Audit Completed" || localStorage.getItem("roleType") === "Reviewer" || localStorage.getItem("roleType") === "Store User" ? (
                                            <Grid container sx={{ paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}>
                                                <Grid item lg={1} md={1} sm={1} xs={12} >
                                                    <Button
                                                        // classes={{ root: classes.button }}
                                                        classes={{ root: classes.auditorButton }}
                                                        variant="contained"
                                                        // size="small"
                                                        color="primary"
                                                        onClick={backToQuestion}
                                                    >
                                                        Back
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        ) : null}
                                    </Box>

                                </>
                            ) : null

                            }
                        </div>
                        {/* send for review */}
                        {auditStatus !== "Audit Completed" && localStorage.getItem("roleType") !== "Reviewer" && localStorage.getItem("roleType") !== "Store User" ? (
                            <>
                                <Box sx={{ paddingLeft: "0rem", paddingRight: "0rem", paddingBottom: "4rem" }}>
                                    <Box id="sendforbutton" sx={{ width: '100%', backgroundColor: "white", borderRadius: "5px" }}>
                                        <h1>Send for Review</h1>
                                        <Formik
                                            initialValues={initialValues}
                                            enableReinitialize={true}
                                            validationSchema={validationSchema}
                                            onSubmit={handleSubmit}
                                        >{({ values, setFieldValue }) => (
                                            <Box sx={{ marginLeft: '0px' }}>

                                                <Form>
                                                    <React.Fragment>

                                                        <Grid container >
                                                            <Grid item xs={12}>
                                                                <Grid container rowSpacing={2}>
                                                                    <Grid sx={{ p: 2 }} item lg={6} md={6} sm={6} xs={12} >
                                                                        <Field as={TextField}
                                                                            name="reviewer"
                                                                            label="Reviewer"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            disabled={localStorage.getItem('disableCheckBox') == 'true' ? true : false}
                                                                            select
                                                                            helperText={<ErrorMessage name="reviewer" />}
                                                                            SelectProps={{
                                                                                value: reviewer,
                                                                                onChange: (e) => {
                                                                                    setReviewer(e.target.value)
                                                                                    setFieldValue("reviewer", e.target.value);
                                                                                    localStorage.setItem("reviewBy", e.target.value)
                                                                                }
                                                                            }}
                                                                        >
                                                                            {rvalue.map((option) => (
                                                                                <MenuItem key={option.userUID} value={option.userUID}>
                                                                                    {option.userName}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Field>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container rowSpacing={2}>
                                                                    <Grid sx={{ p: 2 }} item lg={6} md={6} sm={6} xs={12} >
                                                                        <Field as={TextField}
                                                                            name="localComments"
                                                                            label="Local Comments"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            multiline
                                                                            rows={2}
                                                                            disabled={localStorage.getItem('disableCheckBox') == 'true' ? true : false}
                                                                            helperText={<ErrorMessage name="localComments" />}
                                                                            inputProps={{
                                                                                value: localComments,
                                                                                onChange: (event) => {
                                                                                    setLocalComments(event.target.value)
                                                                                    setFieldValue("localComments", event.target.value);
                                                                                    localStorage.setItem("strictComments", event.target.value)
                                                                                },
                                                                                onPaste: (event) => {
                                                                                    setLocalComments(event.target.value)
                                                                                    setFieldValue("localComments", event.target.value);
                                                                                    localStorage.setItem("strictComments", event.target.value)
                                                                                },
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid sx={{ p: 2 }} item lg={6} md={6} sm={6} xs={12} >
                                                                        <Field as={TextField}
                                                                            name="closingMeetingAttendees"
                                                                            label="Closing Meeting Attendees"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            multiline
                                                                            rows={2}
                                                                            disabled={localStorage.getItem('disableCheckBox') == 'true' ? true : false}
                                                                            helperText={<ErrorMessage name="closingMeetingAttendees" />}
                                                                            inputProps={{
                                                                                value: closingComments,
                                                                                onChange: (event) => {
                                                                                    setClosingComments(event.target.value)
                                                                                    setFieldValue("closingMeetingAttendees", event.target.value);
                                                                                    localStorage.setItem("closingMeetingComments", event.target.value)
                                                                                },
                                                                                onPaste: (event) => {
                                                                                    setClosingComments(event.target.value)
                                                                                    setFieldValue("closingMeetingAttendees", event.target.value);
                                                                                    localStorage.setItem("closingMeetingComments", event.target.value)
                                                                                },
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {/* )
                                                            } */}
                                                            {localStorage.getItem('disableCheckBox') == 'true' ? (null
                                                            ) : (
                                                                <Grid sx={{ marginTop: "10px", marginBottom: "10px" }} container spacing={2} >
                                                                    <Grid sx={{ textAlign: "start", }} item lg={1} md={2} sm={2} xs={4}>
                                                                        <Button
                                                                            // classes={{ root: classes.button }}
                                                                            classes={{ root: classes.button }}
                                                                            variant="contained"
                                                                            size="small"
                                                                            color="primary"
                                                                            onClick={backToQuestion}
                                                                        >
                                                                            Back
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid sx={{ textAlign: "start", }} item lg={4} md={5} sm={10} xs={8}>
                                                                        <Button
                                                                            // classes={{ root: classes.button }}
                                                                            classes={{ root: classes.button }}
                                                                            type="submit"
                                                                            variant="contained"
                                                                            size="small"
                                                                            color="primary"
                                                                            disabled={auditStatus === "Audit Completed" ? true : false}
                                                                        >
                                                                            Send for Reviewer
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                            }


                                                        </Grid>


                                                    </React.Fragment>
                                                </Form>

                                            </Box>
                                        )}
                                        </Formik>
                                    </Box >
                                </Box >
                            </>
                        ) : null}
                        {/* Exit Modal */}
                        <Box>
                            <Modal
                                open={isExitPopup}
                                aria-labelledby="title"
                                aria-describedby="description"
                            >
                                <Box className={classes.popupSmallStyle}>
                                    <Typography id="title" variant="h6" component="h2">
                                        Warning
                                    </Typography>
                                    <Typography id="description" sx={{ mt: 2 }}>
                                        Your uploaded data will be lost if you exit!
                                    </Typography>
                                    <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={closeExitPopup}>
                                        Ok
                                    </Button>
                                    <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={handleCloseExitPopup}>
                                        Cancel
                                    </Button>
                                </Box>
                            </Modal>
                        </Box>
                        {/* Reviewer action Modal */}
                        <Box>
                            <Modal
                                open={isReviewerActionPopup}
                                aria-labelledby="title"
                                aria-describedby="description"
                            >
                                <Box className={classes.popupStoreStyle}>
                                    <Typography id="title" variant="h6" component="h2">
                                        Deviation / Comments
                                    </Typography>
                                    <Formik
                                        initialValues={!isUpdate ? reviewerActionInitialValues : reviewerAction}
                                        enableReinitialize={true}
                                        validationSchema={reviewerActionValidationSchema}
                                        onSubmit={closeReviewerActionPopup}
                                    >
                                        {(values, props, setFieldValue) => (
                                            <Box sx={{ marginLeft: '0px' }}>
                                                <Form>
                                                    <React.Fragment>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Grid container sx={{ paddingTop: "30px" }}>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                                        <Typography sx={{ color: 'black', fontSize: "20px" }}>
                                                                            Question: {reviewerAction.questionName}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                                        <Field as={TextField}
                                                                            name="actionplanComments"
                                                                            label="Deviation / Comments"
                                                                            variant="outlined"
                                                                            value={values?.values?.actionplanComments ?? ""}
                                                                            fullWidth
                                                                            multiline
                                                                            rows={6}
                                                                            helperText={<ErrorMessage name="actionplanComments" />}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }} className={classes.dropZoneContainer}>
                                                                        <DropzoneArea
                                                                            acceptedFiles={['image/*']}
                                                                            dropzoneText={"Drag and drop an max 3 images here or click"}
                                                                            onChange={(files) => {
                                                                                values.setFieldValue('uploadimage', files)
                                                                            }}
                                                                            filesLimit={3}
                                                                            maxFileSize={6000000}
                                                                            initialFiles={getActionPlanImageFiles()}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }} className={classes.dropZoneContainer}>
                                                                        <DropzoneArea
                                                                            acceptedFiles={['video/*']}
                                                                            dropzoneText={"Drag and drop an max 3 videos here or click"}
                                                                            onChange={(files) => {
                                                                                values.setFieldValue('uploadvideo', files)
                                                                            }}
                                                                            filesLimit={3}
                                                                            maxFileSize={6000000}
                                                                            initialFiles={getActionPlanVideoFiles()}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid className={classes.popupbutton} item xs={12} sx={{ margin: "24px", justifyContent: "end", display: "flex" }}>
                                                                <Button size="md" classes={{ root: classes.button }} type="submit" variant="contained">
                                                                    Save
                                                                </Button>
                                                                <Button style={{ marginLeft: "10px" }} classes={{ root: classes.button }} size="md" type="button" variant="contained" onClick={handleCloseReviewerActionPopup}>
                                                                    Cancel
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                </Form>
                                            </Box>
                                        )}
                                    </Formik>

                                </Box>
                            </Modal>
                        </Box>
                        {/* Store action modal */}
                        <Box>
                            <Modal
                                open={isStoreActionPopup}
                                aria-labelledby="title"
                                aria-describedby="description"
                            >
                                <Box className={classes.popupStoreStyle}>
                                    <Typography id="title" variant="h6" component="h2">
                                        Store User Action Plan
                                    </Typography>
                                    <Formik
                                        initialValues={!isUpdate ? StoreActionInitialValues : reviewerAction}
                                        enableReinitialize={true}
                                        validationSchema={storeActionValidationSchema}
                                        onSubmit={closeStoreActionPopup}
                                    >
                                        {(props, values) => (
                                            <Box sx={{ marginLeft: '0px' }}>
                                                <Form>
                                                    <React.Fragment>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Grid container sx={{ paddingTop: "30px" }}>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                                        <TextField
                                                                            label="Question"
                                                                            defaultValue={reviewerAction.questionName}
                                                                            fullWidth
                                                                            multiline
                                                                            rows={4}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                                        <TextField
                                                                            label="Deviation / Comments"
                                                                            defaultValue={reviewerAction.actionplanComments}
                                                                            fullWidth
                                                                            multiline
                                                                            rows={4}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    {reviewerAction.uploadimage.length > 0 ? (
                                                                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                                            <Typography>Uploaded Images :</Typography>
                                                                            <Grid container>
                                                                                {reviewerAction.uploadimage.map((item) => (
                                                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                                        <h5 
                                                                                        style={{cursor:'pointer'}}
                                                                                            onClick={() =>
                                                                                                openImageInNewTab(
                                                                                                    `${imageURL}/${item.imgfilepath}/${item.fileName}`
                                                                                                )
                                                                                            }
                                                                                            download={true}>
                                                                                            {item.fileName}
                                                                                        </h5>
                                                                                    </Grid>
                                                                                ))

                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    ) : (null)

                                                                    }
                                                                    {reviewerAction.uploadvideo.length > 0 ? (
                                                                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                                            <Typography>Uploaded Videos :</Typography>
                                                                            <Grid container>
                                                                                {reviewerAction.uploadvideo.map((item) => (
                                                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                                        <h5
                                                                                         style={{cursor:'pointer'}}
                                                                                            onClick={() =>
                                                                                                openImageInNewTab(
                                                                                                    `${imageURL}/${item.videofilepath}/${item.fileName}`
                                                                                                )
                                                                                            }
                                                                                            download={true}>
                                                                                            {item.fileName}
                                                                                        </h5>
                                                                                    </Grid>
                                                                                ))

                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    ) : (null)

                                                                    }
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                                        <Field as={TextField}
                                                                            name="assignTo"
                                                                            label="Who"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            value={props?.values?.assignTo ?? ""}
                                                                            helperText={<ErrorMessage name="assignTo" />}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ fontSize: "25px", margin: "8px" }}>
                                                                        {/* <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                                            <DatePicker className="w-100" id="datepickerheight"
                                                                                label="When"
                                                                                renderInput={(props) => <TextField {...props} />}
                                                                                name="dueDate"
                                                                                value={dueDate}
                                                                                helperText={<ErrorMessage name="dueDate" />}
                                                                                onChange={(newValue) => {
                                                                                    // console.log(newValue);
                                                                                    // setFieldValue('dueDate', newValue)
                                                                                    setDueDate(newValue);
                                                                                }}
                                                                            />
                                                                        </LocalizationProvider> */}
                                                                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                                            <DatePicker
                                                                                className="w-100" id="datepickerheight"
                                                                                label="When"
                                                                                renderInput={(props) => <TextField {...props} />}
                                                                                name="dueDate"
                                                                                value={dueDate}
                                                                                onChange={(newValue) => {
                                                                                    setDueDate(newValue);
                                                                                }}

                                                                            />
                                                                        </LocalizationProvider>
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }} >
                                                                        <Field as={TextField}
                                                                            name="storecomments"
                                                                            label="What"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            multiline
                                                                            rows={2}
                                                                            value={props?.values?.storecomments ?? ""}
                                                                            helperText={<ErrorMessage name="storecomments" />}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid className={classes.popupbutton} item xs={12} sx={{ margin: "24px", justifyContent: "end", display: "flex" }}>
                                                                <Button size="md" classes={{ root: classes.button }} type="submit" variant="contained">
                                                                    Save
                                                                </Button>
                                                                <Button style={{ marginLeft: "10px" }} classes={{ root: classes.button }} size="md" type="button" variant="contained" onClick={handleCloseStoreActionPopup}>
                                                                    Cancel
                                                                </Button>
                                                            </Grid>
                                                            {/* <Grid item> */}

                                                            {/* </Grid> */}

                                                        </Grid>
                                                    </React.Fragment>
                                                </Form>
                                            </Box>
                                        )}
                                    </Formik>

                                </Box>
                            </Modal>
                        </Box>
                        {/* Complete popup modal */}
                        <Box>
                            <Modal
                                open={isCompletePopup}
                                aria-labelledby="title"
                                aria-describedby="description"
                            >
                                <Box className={classes.popupSmallStyle}>
                                    <Typography id="title" variant="h6" component="h2">
                                        Confirmation
                                    </Typography>
                                    <Typography id="description" sx={{ mt: 2 }}>
                                        Are you sure you want to mark Complete this question?
                                    </Typography>
                                    <Grid container spacing={2} sx={{ marginTop: "20px", justifyContent: "end" }}>
                                        <Grid item>
                                            <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={closeCompletePopup}>
                                                Complete
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={handleCloseCompletePopup}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </Box>
                            </Modal>
                        </Box>
                        {/* Reopen popup modal */}
                        <Box>
                            <Modal
                                open={isReopenPopup}
                                aria-labelledby="title"
                                aria-describedby="description"
                            >
                                <Box className={classes.popupSmallStyle}>
                                    <Typography id="title" variant="h6" component="h2">
                                        Confirmation
                                    </Typography>
                                    <Typography id="description" sx={{ mt: 2 }}>
                                        Are you sure you want to Reopen this question?
                                    </Typography>
                                    <Grid container spacing={2} sx={{ marginTop: "20px", justifyContent: "end" }}>
                                        <Grid item>
                                            <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={closeReopenPopup}>
                                                Reopen
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={handleCloseReopenPopup}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </Box>
                            </Modal>
                        </Box>
                        {/* Upload modal */}
                        <Box>
                            <Modal
                                open={isStoreUploadPopup}
                                aria-labelledby="title"
                                aria-describedby="description"
                            >
                                <Box className={classes.popupStyle}>
                                    <Typography id="title" variant="h6" component="h2">
                                        Upload
                                    </Typography>
                                    <Box sx={{ padding: "1rem", }} className={classes.dropZoneContainer}>
                                        <DropzoneArea
                                            // acceptedFiles={['image/*']}
                                            dropzoneText={"Drag and drop an max 5 files here or click"}
                                            onChange={(files) => {
                                                setUploadFiles(files);
                                            }}
                                            filesLimit={5}
                                            maxFileSize={9000000}
                                            initialFiles={getInputUploadfiles()}
                                        />
                                    </Box>
                                    <Grid container spacing={2} sx={{ marginTop: "20px", justifyContent: "end" }}>
                                        <Grid item>
                                            <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={closeStoreUploadPopup}>
                                                Upload
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={handleCloseStoreUploadPopup}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Modal>
                        </Box>
                        {/* Reviewer view popup modal */}
                        <Box>
                            <Modal
                                open={isReviewerViewPopup}
                                aria-labelledby="title"
                                aria-describedby="description"
                            >
                                <Box className={classes.popupStyle}>
                                    <Typography id="title" variant="h6" component="h2">
                                        Action Plan Comment
                                    </Typography>
                                    <Box sx={{ marginLeft: '0px' }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container sx={{ paddingTop: "30px" }}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                        <TextField
                                                            label="Question"
                                                            defaultValue={reviewerAction.questionName}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                        <TextField
                                                            label="Answer"
                                                            defaultValue={reviewerAction.valuation}
                                                            fullWidth
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                        <TextField
                                                            label="Deviation / Comments"
                                                            defaultValue={reviewerAction.actionplanComments}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.popupbutton} item xs={12} sx={{ margin: "24px", justifyContent: "end", display: "flex" }}>
                                                <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={handleCloseReviewerViewPopup}>
                                                    Ok
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Modal>
                        </Box>
                        {/* Store view  popup modal */}
                        <Box>
                            <Modal
                                open={isStoreViewPopup}
                                aria-labelledby="title"
                                aria-describedby="description"
                            >
                                <Box className={classes.popupStoreStyle}>
                                    <Typography id="title" variant="h6" component="h2">
                                        Action Plan Comment
                                    </Typography>
                                    <Box sx={{ marginLeft: '0px' }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container sx={{ paddingTop: "30px" }}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                        <TextField
                                                            label="Question"
                                                            defaultValue={reviewerAction.questionName}
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                        <TextField
                                                            label="Deviation / Comments"
                                                            defaultValue={reviewerAction.actionplanComments}
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                        <TextField
                                                            label="Who"
                                                            defaultValue={reviewerAction.assignTo}
                                                            fullWidth
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                        <TextField
                                                            label="When"
                                                            defaultValue={new Date(reviewerAction.dueDate)}
                                                            fullWidth
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                        <TextField
                                                            label="What"
                                                            defaultValue={reviewerAction.storecomments}
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "8px" }}>
                                                        <DropzoneArea
                                                            dropzoneClass={classes.DropzoneAreaDisabled}
                                                            dropzoneProps={{ disabled: true }}
                                                            dropzoneText={uploadFiles.length > 0 ? "Uploaded Files" : "No files uploaded for this question"}
                                                            filesLimit={5}
                                                            maxFileSize={9000000}
                                                            initialFiles={getInputUploadfiles()}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.popupbutton} item xs={12} sx={{ margin: "24px", justifyContent: "end", display: "flex" }}>
                                                <Button size="md" classes={{ root: classes.button }} type="button" variant="contained" onClick={handleCloseStoreViewPopup}>
                                                    Ok
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </Box>
                                </Box>
                            </Modal>
                        </Box>
                    </Box>
                </>
            )
            }

        </>
    )
}

