import { makeStyles } from "@material-ui/styles";
import ikea from '../../images/ikea.jpg';
import loginbanner from '../../images/loginbanner.png'
import { Repeat } from "@material-ui/icons";
import ikea3 from '../../images/ikea3.jpg'

export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  loginContainer: {
    backgroundImage: `url(${ikea})`,
    // height: "100%",
    // width: "100%",
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    // backgroundImage: `url(${ikea})`,
    // width: "100%",
    // height: "100%",
    /*[theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },*/
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  logintypo: {
    fontWeight: 400,
    fontSize: 18,
  },

  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    backgroundColor: "#f6f7ff",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      right: "22px",
      position: "relative",
      '@media (orientation: landscape)': {
        width: "50%",
        right: "67px",
        position: "relative",
      },
      '@media (orientation: portrait)': {
        width: "100%",
        right: "75%",
        top: "50%",
        position: "relative"
      },
    },
    [theme.breakpoints.down("xs")]: {
      right: "22px",
      position: "relative",
      '@media (orientation: landscape)': {
        width: "50%",
      },
      '@media (orientation: portrait)': {
        width: "100%",
      },
    },
  },
  form: {
    width: 320,
    [theme.breakpoints.down('xs')]: {
      width: 275,
    }
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
  loginpagewhiteboxres: {
    top: "18%",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      position: "relative",

      width: "100%",
      height: "100%",
      left: "0px",

    },
  },
  logintypo: {
    fontWeight: 400,
    fontSize: 18,
  },
  loginpageblueboxres: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  loginbanner: {
    backgroundImage: `url(${ikea})`,
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      backgroundImage: `url(${ikea3})`,
      height: "87vh !important",
      backgroundRepeat: "no- repeat !important"

    }
  },
  loginbanner2: {
    backgroundImage: `url(${loginbanner})`,
  },
  responsive: {
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  loginresponsive: {
    [`${theme.breakpoints.up('xs')} and (orientation: portrait)`]: {
      paddingTop: "15%",
    }

  },
}));
