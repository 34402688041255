import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({

    footerStyles: {
        color: '#f6f7ff',
        background: '#f6f7ff',
        // top: '95%',
        bottom: "0",
        position: "fixed",
        width: "100%",
        zIndex: "1",
        [theme.breakpoints.down("md")]: {
            '@media (orientation: landscape)': {
                // top: '94%',
            },
        },
        [theme.breakpoints.down("md")]: {
            '@media (orientation: portrait)': {

                // top: '96%',
                fontSize: "11px"
            },
        },
    },
    linkContainer: {
        display: 'flex',

    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        padding: '2%',
        // paddingTop: '1%'
    },
    aStyles: {
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        },
        lineHeight: '180%'
    },
    bottom: {
        textAlign: 'center',
        padding: '1%'
    }

}));