import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import Grid from '@mui/material/Grid';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MenuItem } from "@mui/material";
import { apiPost } from "../../apiCommon";
import { config } from "../../config";
import localforage from 'localforage';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useHistory } from "react-router-dom";
import "../../style.css";
import { toast } from "react-toastify";
import { ThreeDots } from 'react-loader-spinner';
const FoodAudit = localforage.createInstance({
    name: "IKEADB",
    storeName: "FoodAudit"
});

export default function AddNewForm(props) {
    const [value, setValue] = React.useState(new Date());
    const [auditvalue, setAuditValue] = useState([]);
    const [countryValues, setCountryValues] = useState([]);
    const [storeValues, setStoreValues] = useState([]);
    const [isClosingMeetingAttendees, setIsClosingMeetingAttendees] = useState(false);
    const [isOpeningMeetingAttendees, setIsOpeningMeetingAttendees] = useState(false);
    const [auditName, setAuditName] = useState("New Audit Form");
    const [selectedAuditType, setSelectedAuditType] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [storeNewInitialValues, setStoreNewInitialValues] = useState({});
    let history = useHistory();
    const initialValues = {
        country: "",
        storeName: "",
        managerOnduty: "",
        foodManager: "",
        openingmeetingAttendees: "",
        closingmeetingAttendees: "",
        auditType: "",
    }

    // roleuid :
    //  2 - foodsafety
    //  3 - property
    //  4 - dmchecklist
    //  5 - security 


    useEffect(() => {

        async function fetchData() {
            const response = await apiPost(config.getAuditEntity);
            const auditValues = [];
            response.data.data.forEach((value) => {
                auditValues.push({ "auditEntityName": value.auditEntityName, "auditEntityUID": value.auditEntityUID });
            });
            setAuditValue([...auditValues]);
            getAuditName(localStorage.getItem("roleUID"));
        }
        fetchData();

        async function getcountry() {
            const response = await apiPost(config.getcountry);
            const countryValue = [];
            response.data.data.forEach((value) => {
                countryValue.push({ "label": value.countryName, "value": value.countryUID });
            });
            setCountryValues([...countryValue]);
            const storeData = await FoodAudit.getItem("storeData");
            if (storeData) {
                let sCountryUID = 0;
                let sCountry = '';
                countryValue.forEach((val) => {
                    if (val.label == storeData.country) {
                        sCountryUID = val.value;
                        sCountry = val.label;
                    }
                });
                getstorebycountry(sCountryUID);
                setIsUpdate(true);
                setStoreNewInitialValues(storeData)
                setValue(new Date(storeData.auditDate))

            }
        }
        getcountry();


    }, [])


    function getAuditName(value) {
        switch (value) {
            case "2":
                {
                    setAuditName("Food Safety Audit Form");
                    setSelectedAuditType(1);
                    handleAuditTypeChange(1);
                    break;
                }
            case "3":
                {
                    setAuditName("Property Audit Form");
                    setSelectedAuditType(2);
                    handleAuditTypeChange(2);
                    break;
                }
            case "4":
                {
                    setAuditName("Duty Manager Checklist");
                    setSelectedAuditType(3);
                    handleAuditTypeChange(4);
                    break;
                }
            case "5":
                {
                    setAuditName("New SEC Checklist");
                    setSelectedAuditType(4);
                    handleAuditTypeChange(4);
                    break;
                }
            default:
                {
                    setAuditName("New Audit Form");
                    setSelectedAuditType(0);
                    break;
                }
        }
    }

    const handleCountryChange = (e, props) => {
        let sCountry = '';
        let sCountryUID = 0;
        countryValues.forEach((val) => {
            if (val.label == e.target.value) {
                sCountryUID = val.value;
                sCountry = val.label;
            }
        });
        props.setFieldValue("country", sCountry);
        getstorebycountry(sCountryUID);
    };

    async function getstorebycountry(v) {
        let data = {
            "countryUID": v,
            "userUID": localStorage.getItem("userUID")
        };
        const response = await apiPost(config.getstorebycountry, data);
        const storeValue = [];
        response.data.data.forEach((value) => {
            storeValue.push({ "label": value.storeName, "value": value.storeUID });
        });
        setStoreValues([...storeValue]);
    }


    const foodValidationSchema =
        Yup.object().shape({
            country: Yup.string().required("required Country Name"),
            storeName: Yup.string().required("required Store Name"),
            managerOnduty: Yup.string().required("required Manager on Duty"),
            openingmeetingAttendees: Yup.string().required("required Open Meeting Attendees"),
            //closingmeetingAttendees: Yup.string().required("required ClosingMeetingAttendees"),
        });
    const propertyValidationSchema =
        Yup.object().shape({
            country: Yup.string().required("required Country Name"),
            storeName: Yup.string().required("required Store Name"),
            managerOnduty: Yup.string().required("required Manager on Duty"),
            openingmeetingAttendees: Yup.string().required("required Open Meeting Attendees"),
            closingmeetingAttendees: Yup.string().required("required Close Meeting Attendees"),
        });
    const dmSecValidationSchema =
        Yup.object().shape({
            country: Yup.string().required("required Country Name"),
            storeName: Yup.string().required("required Store Name"),
            managerOnduty: Yup.string().required("required Duty Manager"),
        });

    function getApiName(value) {
        switch (value) {
            case 1:
                return (
                    config.getFAQuestions
                );
            case 2:
                return (
                    config.getPAQuestions
                );
            case 3:
                return (
                    config.getDMQuestions
                );
            case 4:
                return (
                    config.getSSQuestions
                );
            default:
                throw new Error("Unknown step");
        }
    }

    function getValidationSchema() {
        switch (selectedAuditType) {
            case 1:
                return (
                    foodValidationSchema
                );
            case 2:
                return (
                    propertyValidationSchema
                );
            case 3:
                return (
                    dmSecValidationSchema
                );
            case 4:
                return (
                    dmSecValidationSchema
                );
            default:
                return (
                    dmSecValidationSchema
                );
        }
    }

    function handleAuditTypeChange(value) {
        switch (value) {
            case 1:
                {
                    setIsClosingMeetingAttendees(false);
                    setIsOpeningMeetingAttendees(true);
                    break;
                }
            case 2:
                {
                    setIsClosingMeetingAttendees(true);
                    setIsOpeningMeetingAttendees(true);
                    break;
                }
            case 3:
                {
                    setIsClosingMeetingAttendees(false);
                    setIsOpeningMeetingAttendees(false);
                    break;
                }
            case 4:
                {
                    setIsClosingMeetingAttendees(false);
                    setIsOpeningMeetingAttendees(false);
                    break;
                }
            default:
                {
                    setIsClosingMeetingAttendees(false);
                    setIsOpeningMeetingAttendees(false);
                    break;
                }
        }
    }

    async function handleSubmit(values) {
        if (localStorage.getItem('internetFlag') == 'false') {
            toast.error('You are not online so not able to start the audit');
            return;
        }
        localStorage.setItem('auditType', selectedAuditType);
        localStorage.setItem("closingMeetingAttendees", values.closingmeetingAttendees);
        localStorage.setItem("strictComments", "");
        localStorage.setItem('closingMeetingComments', values.closingmeetingAttendees);
        localStorage.setItem("reviewBy", "");
        localStorage.setItem("actionPlan", "");
        localStorage.setItem("disableCheckBox", 'false');
        let data = {
            "country": values.country,
            "storeName": values.storeName,
            "managerOnduty": values.managerOnduty,
            "foodManager": 'FManager1',
            "openingmeetingAttendees": values.openingmeetingAttendees,
            "closingmeetingAttendees": values.closingmeetingAttendees,
            "userUID": localStorage.getItem("userUID"),
            "auditEntityUID": selectedAuditType,
            "auditDate": format(new Date(value), 'yyyy-MM-dd  hh:mm a')
        }
        setLoading(true);
        const response = await apiPost(getApiName(selectedAuditType), data)
        setLoading(false);
        localStorage.setItem('newAudit', 'true');
        // console.log(response.data)
        // const transactionUID = response.data.data.questionDetails[0].auditSubTypeQuestionDetails[0].auditSubtypeUIDDetails[0].transactionUID
        const newFinalData = response.data.data.questionDetails.map((q, i) => {
            const data1 = q?.auditSubTypeQuestionDetails.map((q1, i1) => {
                const data2 = q1?.auditSubtypeUIDDetails.map((q2, i2) => {
                    return {
                        ...q2,
                        "valuation": "",
                        "comments": "",
                        "images": [],
                        "videos": [],
                        "standard": ""
                    }
                })
                return {
                    "auditSubtypeName": q1.auditSubtypeName,
                    "auditSubtypeUID": q1.auditSubtypeUID,
                    "auditSubtypeUIDDetails": data2
                }
            })
            return {
                "auditTypeUID": q.auditTypeUID,
                "auditTypeName": q.auditTypeName,
                "auditSubTypeQuestionDetails": data1
            }
        })
        // console.log(newFinalData)
        await FoodAudit.removeItem("answerresponseDetails");
        await FoodAudit.removeItem("questionDetails");
        await FoodAudit.removeItem("storedAnswers");
        await FoodAudit.setItem("questionDetails", newFinalData)
        await FoodAudit.setItem("answerresponseDetails", response.data.data.answerresponseDetails)
        props.history.push({ pathname: "/app/ui/questionnaire", state: { data: response.data, from: "auditForm" } })
    }

    async function handleStoreUpdate(values) {
        if (localStorage.getItem('internetFlag') == 'false') {
            toast.error('You are not online so not able to start the audit');
            return;
        }
        let data = {
            "auditUID": localStorage.getItem("auditUID"),
            "country": values.country,
            "storeName": values.storeName,
            "managerOnduty": values.managerOnduty,
            "openingmeetingAttendees": values.openingmeetingAttendees,
            "auditDatetime": format(new Date(value), 'yyyy-MM-dd  hh:mm a')
        }
        setLoading(true);
        const response = await apiPost(config.updateAuditData, data)
        setLoading(false);

        if (response) {
            if (response.data.data[0].status === "Updated Successfully") {
                toast.success('Updated Successfully');
                await FoodAudit.removeItem("storeData");
                setTimeout(() => {
                    history.push({ pathname: "/app/dashboard" });
                }, 1000);
            }
            else {
                toast.error(response.data.message);
            }
        }
        else {
            toast.error('Something went wrong try again later!');
            await FoodAudit.removeItem("storeData");
            setTimeout(() => {
                history.push({ pathname: "/app/dashboard" });
            }, 3000);
        }
    }

    async function goToDashboard() {
        await FoodAudit.removeItem("storeData");
        history.push({ pathname: "/app/dashboard" });
    }


    return (
        <>
            {loading ? (
                <>
                    <ThreeDots

                        color="#00BFFF"
                        height={100}
                        width={100}
                        wrapperStyle={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "13rem"
                        }}
                    />
                </>
            ) : (
                <>
                    <Grid container className="_audit_form" sx={{ paddingTop: "70px", }}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item lg={8} md={8} sm={6} xs={12} sx={{ textAlign: 'start', paddingLeft: '0', }}>
                                    <PageTitle title={auditName} />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} sx={{ textAlign: 'end', paddingRight: '0', paddingBottom: '15px' }}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            background: "#0257a7",
                                            '&:hover': {
                                                background: "#0257a7",
                                            },
                                        }}
                                        onClick={goToDashboard}
                                    >
                                        Back
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="_audit_formik">
                            <Formik
                                initialValues={!isUpdate ? initialValues : storeNewInitialValues}
                                enableReinitialize={true}
                                validationSchema={getValidationSchema}
                                onSubmit={!isUpdate ? handleSubmit : handleStoreUpdate}
                            >
                                {(props) => (
                                    <Box sx={{ marginLeft: '0px' }}>
                                        <Form>
                                            <React.Fragment>
                                                <Grid container spacing={0} justify="center">
                                                    <Grid item lg={6} md={6} xs={12}>
                                                        <Grid container sx={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} sx={{ padding: "10px" }}>
                                                                <Field as={TextField}

                                                                    name="country"
                                                                    label="Country"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    value={props?.values?.country ?? ""}
                                                                    SelectProps={{
                                                                        onChange: (e) => { handleCountryChange(e, props) }
                                                                    }}
                                                                    helperText={<ErrorMessage name="country" />}
                                                                >
                                                                    {countryValues.map((option) => (
                                                                        <MenuItem key={option.value} value={option.label}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={6} xs={12} >
                                                        <Grid container sx={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} sx={{ padding: "10px" }}>
                                                                <Field as={TextField}
                                                                    name="storeName"
                                                                    label="Store Name"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    value={props?.values?.storeName ?? ""}
                                                                    helperText={<ErrorMessage name="storeName" />}
                                                                >
                                                                    {storeValues.map((option) => (
                                                                        <MenuItem key={option.value} value={option.label}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={6} xs={12} >
                                                        <Grid sx={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} sx={{ padding: "10px" }}>
                                                                <Field as={TextField}
                                                                    name="managerOnduty"
                                                                    label={selectedAuditType === 1 || selectedAuditType === 2 ? "Manager on Duty" : "Duty Manager"}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    InputProps={{
                                                                        onKeyPress: (e) => {
                                                                            if (e.key === "Enter") {
                                                                                document.getElementById("submitBtn").click();
                                                                            }
                                                                        }
                                                                    }}
                                                                    value={props?.values?.managerOnduty ?? ""}
                                                                    helperText={<ErrorMessage name="managerOnduty" />}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item md={6} xs={12} sx={{ fontSize: "25px" }}>
                                                        <Grid container sx={{ fontSize: "25px", marginTop: "10px" }}>
                                                            <Grid item xs={12} sx={{ padding: "10px" }}>
                                                                <Field as={TextField}
                                                                    name="auditType"
                                                                    label="Audit Type"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    disabled={true}
                                                                    SelectProps={{
                                                                        value: selectedAuditType,
                                                                        // onChange :  (e) =>{handleAuditTypeChange(e,props)}
                                                                    }}
                                                                    helperText={<ErrorMessage name="auditType" />}
                                                                >
                                                                    {auditvalue.map((option) => (
                                                                        <MenuItem key={option.auditEntityUID} value={option.auditEntityUID}>
                                                                            {option.auditEntityName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> */}
                                                    {isOpeningMeetingAttendees ? (
                                                        <Grid item md={6} xs={12} >
                                                            <Grid container sx={{ marginTop: "10px" }}>
                                                                <Grid item xs={12} sx={{ padding: "10px" }} >
                                                                    <Field as={TextField}
                                                                        name="openingmeetingAttendees"
                                                                        label="Open Meeting Attendees"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            onKeyPress: (e) => {
                                                                                if (e.key === "Enter") {
                                                                                    document.getElementById("submitBtn").click();
                                                                                }
                                                                            }
                                                                        }}
                                                                        multiline
                                                                        rows={2}
                                                                        value={props?.values?.openingmeetingAttendees ?? ""}
                                                                        helperText={<ErrorMessage name="openingmeetingAttendees" />}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ) : null}
                                                    {isClosingMeetingAttendees ? (
                                                        <Grid item md={6} xs={12} >
                                                            <Grid container sx={{ marginTop: "10px" }}>
                                                                <Grid item xs={12} sx={{ padding: "10px" }}>
                                                                    <Field as={TextField}
                                                                        name="closingmeetingAttendees"
                                                                        label="Close Meeting Attendees"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            onKeyPress: (e) => {
                                                                                if (e.key === "Enter") {
                                                                                    document.getElementById("submitBtn").click();
                                                                                }
                                                                            }
                                                                        }}
                                                                        multiline //for converting text area
                                                                        rows={2}
                                                                        value={props?.values?.closingmeetingAttendees ?? ""}
                                                                        helperText={<ErrorMessage name="closingmeetingAttendees" />}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ) : null}


                                                    <Grid item md={6} xs={12} sx={{ fontSize: "25px" }}>
                                                        <Grid container sx={{ fontSize: "25px", marginTop: "10px" }}>
                                                            <Grid item xs={12} sx={{ padding: "10px" }}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                                    <DateTimePicker
                                                                        className="w-100" id="datepickerheight"
                                                                        label="Date"
                                                                        viewRenderers={{
                                                                            hours: renderTimeViewClock,
                                                                            minutes: renderTimeViewClock,
                                                                            seconds: renderTimeViewClock,
                                                                          }}
                                                                        renderInput={(props) => <TextField {...props} InputProps={{
                                                                            onKeyPress: (e) => {
                                                                                if (e.key === "Enter") {
                                                                                    document.getElementById("submitBtn").click();
                                                                                }
                                                                            }
                                                                        }} />}
                                                                        name="Date"
                                                                        value={value}
                                                                        onChange={(newValue) => {
                                                                            console.log(newValue);
                                                                            console.log(format(new Date(newValue), 'yyyy-MM-dd  hh:mm a'))
                                                                            setValue(newValue);
                                                                        }}

                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{ marginTop: "20px" }}>
                                                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                                            <Button size="lg" type="submit" id="submitBtn" variant="contained"
                                                                sx={{
                                                                    background: "#0257a7",
                                                                    '&:hover': {
                                                                        background: "#0257a7",
                                                                    },
                                                                }}>
                                                                {!isUpdate ? "Start New Audit" : "Update"}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        </Form>
                                    </Box>
                                )}
                            </Formik>
                        </Grid>
                    </Grid >
                </>
            )
            }
        </>
    )
}